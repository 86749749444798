import React, { memo } from 'react';

import { PageBlock } from '../..';

import styles from './index.module.scss';

function ImageSectionBlock({ block }: { block: PageBlock }) {
  return (
    <div className={styles.imagesContainer}>
      {block.firstImage && <img src={block.firstImage} alt="first_image" />}
      {block.secondImage && <img src={block.secondImage} alt="second_image" />}
    </div>
  );
}

export default memo(ImageSectionBlock);
