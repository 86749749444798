import React, { memo } from 'react';

import { PageBlock } from '../..';

import styles from './index.module.scss';

function CatalogueBlock({ block }: { block: PageBlock }) {
  return (
    <div className={styles.catalogueContainer}>
      {block.items && block.items.length > 0 && (
        <div className={styles.grid}>
          {block.items.map((product: any) => (
            <div key={product.catalogue_product_id} className={styles.gridItem}>
              <img src={product.thumbnail} alt={product.name} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default memo(CatalogueBlock);
