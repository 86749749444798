import React, { useEffect, useState, memo } from 'react';

import { Row, Col, FormGroup, Input, Label, Spinner } from 'reactstrap';

import SecretKeyInput from './SecretKeyInput';

import BtnCopy from '~/components/BtnCopy';

import api from '~/services/api';

import util from '~/assets/util';

function Form() {
  const [credentials, setCredentials]: any = useState({
    public_key: '',
    base_url: '',
  });

  const [loading, setLoading] = useState(true);

  const doSave = () => {};

  useEffect(() => {
    async function load() {
      try {
        const res = await api.get('admin/tenants/integration');

        const { public_key, base_url } = res.data;

        setCredentials({ ...credentials, public_key, base_url });
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    load();
  }, []);

  if (loading) {
    return <Spinner size="lg" className="d-block m-auto" />;
  }

  return (
    <form onSubmit={doSave} className="container-fluid p-3">
      <Row form>
        <Col xl="4">
          <FormGroup>
            <Label>{util.t('BASE_URL')}</Label>
            <Input
              className="form-control"
              value={credentials.base_url}
              type="url"
              name="base_url"
              disabled
            />
            <BtnCopy className="mt-2" text={credentials.base_url} />
          </FormGroup>
        </Col>
        <Col xl="4">
          <FormGroup>
            <Label>{util.t('PUBLIC_KEY')}</Label>
            <Input
              className="form-control"
              value={credentials.public_key}
              type="text"
              name="public_key"
              disabled
            />
            <BtnCopy className="mt-2" text={credentials.public_key} />
          </FormGroup>
        </Col>
        <Col xl="4">
          <FormGroup>
            <Label htmlFor="name">{util.t('SECRET_KEY')}</Label>
            <SecretKeyInput />
          </FormGroup>
        </Col>
      </Row>
    </form>
  );
}

export default memo(Form);
