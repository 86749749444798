import React, { memo, useCallback, useMemo, useState } from 'react';

import { format, parseISO } from 'date-fns';

import { Button, Spinner, Table } from 'reactstrap';

import { FaSearchPlus } from 'react-icons/fa';
import { BiInfinite } from 'react-icons/bi';

import DeleteButton from '../DeleteButton';

import api from '~/services/api';
import notification from '~/services/notification';

import { CouponType } from '~/assets/constants';

import util from '~/assets/util';

interface CouponsTableProps {
  coupons: any;
  loading?: any;
  onOpen?: any;
  doUpdate?: any;
}

function CouponsTable({ coupons, loading = false, onOpen, doUpdate }: CouponsTableProps) {
  const [deleting, setDeleting] = useState([]);

  const doDelete = useCallback(
    async (id) => {
      try {
        setDeleting(deleting.concat([id]));

        const url = `admin/coupons/${id}`;

        await api.delete(url);

        doUpdate();
      } catch (e) {
        notification.$e(e);
      } finally {
        setDeleting(deleting.filter((deletingId) => deletingId != id));
      }
    },
    [deleting, doUpdate],
  );

  const list = useMemo(
    () =>
      coupons?.data?.map((coupon) => {
        const type =
          coupon.type == CouponType.PERCENT_DISCOUNT
            ? `${util.t('PERCENT')} (${coupon.value}%)`
            : coupon.type == CouponType.CASH
            ? util.t('VOUCHER')
            : util.t('FREE_SHIPPING');

        const validity = coupon.expires_at ? (
          <>
            {util.t(coupon.expired ? 'EXPIRED_AT' : 'VALID_UNTIL')}{' '}
            <strong>{format(parseISO(coupon.expires_at), `dd/MM/yyyy 'às' HH:mm`)}</strong>{' '}
          </>
        ) : (
          <BiInfinite />
        );

        return (
          <tr key={coupon.coupon_id}>
            <td>
              <strong>{coupon.code}</strong>
            </td>
            <td>{type}</td>
            <td>{validity}</td>
            <td>{util.t(coupon.active ? 'ACTIVE' : 'INACTIVE')}</td>
            <td>
              <div className="d-flex align-center justify-content-end">
                <Button
                  className="mr-1"
                  onClick={() => onOpen(coupon.coupon_id)}
                  size="sm"
                  outline
                  color="info">
                  <FaSearchPlus />
                </Button>

                <DeleteButton
                  onDelete={() => doDelete(coupon.coupon_id)}
                  loading={deleting.indexOf(coupon.coupon_id) > -1}
                  size="sm"
                />
              </div>
            </td>
          </tr>
        );
      }),
    [coupons, deleting, doDelete, onOpen],
  );

  const renderBody = () => {
    if (loading) {
      return (
        <tr>
          <th className="text-center w-100" colSpan={6}>
            <Spinner size="sm" color="primary" />
          </th>
        </tr>
      );
    }

    if (coupons?.data?.length) {
      return list;
    }

    return (
      <tr>
        <td className="text-center" colSpan={5}>
          <span>{util.t('NO_RECORDS_FOUND')}</span>
        </td>
      </tr>
    );
  };

  return (
    <>
      <Table hover responsive>
        <thead className="thead-light">
          <tr>
            <th>{util.t('CODE')}</th>
            <th>{util.t('DISCOUNT_TYPE')}</th>
            <th>{util.t('VALIDITY')}</th>
            <th>{util.t('STATUS')}</th>
            <th className="text-right">{util.t('ACTION')}</th>
          </tr>
        </thead>

        <tbody>{renderBody()}</tbody>
      </Table>
    </>
  );
}

export default memo(CouponsTable);
