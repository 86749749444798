import React, { memo, useEffect, useState } from 'react';

import { Spinner } from 'reactstrap';

import Select from 'react-select';
import Creatable from 'react-select/creatable';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

interface SchoolSelectProps {
  onChange: any;
  allowCreate?: boolean;
  placeholder?: string;
  [key: string]: any;
}

function SchoolSelect({ onChange, allowCreate = true, ...props }: SchoolSelectProps) {
  const [loading, setLoading] = useState(true);
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);

  const loadSchools = async () => {
    try {
      const res = await api.get('admin/schools');

      const { schools } = res?.data;

      setSchools(schools);

      onChange(null);
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleCreate = (inputValue: string) => {
    const newSchool = { school_id: null, name: inputValue };

    setSchools((prevSchools) => [...prevSchools, newSchool]);
    setSelectedSchool(newSchool);
  };

  useEffect(() => {
    onChange(selectedSchool);
  }, [selectedSchool]);

  useEffect(() => {
    loadSchools();
  }, []);

  if (loading) {
    return <Spinner color="primary" className="d-block mx-auto mb-3" size="sm" />;
  }

  const SelectComponent = allowCreate ? Creatable : Select;

  return (
    <SelectComponent
      {...props}
      isClearable
      getOptionLabel={(opt) => opt.name || opt.label}
      getOptionValue={(opt) => opt.school_id || opt.name}
      noOptionsMessage={() => util.t('NO_OPTION')}
      options={schools}
      placeholder={props.placeholder || util.t('SELECT_OR_TYPE_A_NEW_SCHOOL')}
      onChange={(value) => setSelectedSchool(value)}
      value={selectedSchool}
      {...(allowCreate && {
        formatCreateLabel: (name: string) => `${util.t('LABEL_INSERT')} "${name}"`,
        onCreateOption: handleCreate,
      })}
    />
  );
}

export default memo(SchoolSelect);
