import React, { memo } from 'react';

import { Button } from 'reactstrap';

import { FaPencilAlt, FaTrash } from 'react-icons/fa';

import CatalogueBlock from './CatalogueBlock';
import ImageSectionBlock from './ImageSectionBlock';

import util from '~/assets/util';

import { PageBlock } from '..';

interface BlockItemProps {
  block: PageBlock;
  onRemove: () => void;
  onEditImageSection: () => void;
  onEditCatalogue: () => void;
}

function BlockItem({ block, onRemove, onEditImageSection, onEditCatalogue }: BlockItemProps) {
  const label = block.type === 'catalogue' ? 'CATALOGUE' : 'IMAGE_SECTION';

  return (
    <section>
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="mb-0 text-capitalize">
          {util.t(label)} {`"${block.title}"`}
        </h5>
        <div>
          <Button
            color="warning"
            onClick={block.type === 'catalogue' ? onEditCatalogue : onEditImageSection}>
            <FaPencilAlt />
          </Button>
          <Button color="danger" className="ml-2" onClick={onRemove}>
            <FaTrash />
          </Button>
        </div>
      </div>

      <hr />

      {block.type === 'catalogue' && <CatalogueBlock block={block} />}
      {block.type === 'image-section' && <ImageSectionBlock block={block} />}
    </section>
  );
}

export default memo(BlockItem);
