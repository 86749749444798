import React, { memo, useState } from 'react';

import { Button, Table } from 'reactstrap';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

interface ShippingResumeProps {
  storeOrder: any;
  setRefreshOrder: any;
}

function ShippingResume({ storeOrder, setRefreshOrder }: ShippingResumeProps) {
  const [loading, setLoading] = useState(false);
  const [shippingCode, setShippingCode] = useState('');

  const updateShippingCode = async () => {
    try {
      if (loading) {
        return false;
      }

      setLoading(true);

      const id = storeOrder.store_order_id;

      const formData = {
        shippingCode: shippingCode,
        note: '',
        status: 3,
      };

      const res = await api.put(`admin/orders/shipping-code/${id}`, formData);

      const { result } = res?.data;

      if (result) {
        notification.$s('Código de rastreio adicionado com sucesso!');

        setRefreshOrder(true);
      }
    } catch (e) {
      notification.$e(e);
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Table bordered>
      <tbody>
        <tr>
          <th>{util.t('RECEIVER')}</th>
          <td>{storeOrder.shipping_name}</td>
        </tr>
        <tr>
          <th>{util.t('ADDRESS')}</th>
          <td>
            {storeOrder.shipping_street}, {storeOrder.shipping_number}
          </td>
        </tr>
        <tr>
          <th>{util.t('ADDRESS_NEIGHBORHOOD')}</th>
          <td>{storeOrder.shipping_neighborhood}</td>
        </tr>
        <tr>
          <th>{util.t('CITY_STATE')}</th>
          <td>
            {storeOrder.shipping_city} / {storeOrder.shipping_state}
          </td>
        </tr>
        <tr>
          <th>{util.t('ADDRESS_ZIPCODE')}</th>
          <td>{storeOrder.shipping_zipcode}</td>
        </tr>
        {storeOrder.shipping_complement?.length > 0 && (
          <tr>
            <th>{util.t('ADDRESS_COMPLEMENT')}</th>
            <td>{storeOrder.shipping_complement}</td>
          </tr>
        )}
        {storeOrder.shipping_code?.length > 0 ? (
          <tr>
            <th>{util.t('SHIPPING_CODE')}</th>
            <td>
              <a href={storeOrder.shipping_code_url} target="_blank" rel="noreferrer">
                {storeOrder.shipping_code}
              </a>
            </td>
          </tr>
        ) : (
          <tr>
            <th>{util.t('SHIPPING_CODE')}</th>
            <td>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                <input
                  type="text"
                  disabled={!loading ? false : true}
                  onChange={(ev) => setShippingCode(ev.target.value)}
                  className="form-control mr-2"
                />
                <Button
                  size="sm"
                  color="primary"
                  onClick={updateShippingCode}
                  disabled={shippingCode.length > 3 ? false : true}>
                  {!loading ? 'Adicionar' : 'Aguarde...'}
                </Button>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

export default memo(ShippingResume);
