import React, { useEffect, useState } from 'react';

import { Button } from 'reactstrap';

import ImageSectionModal from '~/components/ImageSectionModal';
import ImageSectionTable from '~/components/ImageSectionTable';
import Pagination from '~/components/Pagination';

import Filters from './Filters';

import api from '~/services/api';

import util from '~/assets/util';

import { ImageSectionType } from '~/types/image-section';

const DEFAULT_FILTERS = {
  active: '',
  title: '',
  type: ImageSectionType.HOME_PAGE,
};

function Sections() {
  const [loading, setLoading] = useState(true);
  const [sections, setSections] = useState(null);

  const [filters, setFilters]: any = useState(DEFAULT_FILTERS);

  const [bannerId, setBannerId] = useState(null);

  const doSearch = async (page: any = 1) => {
    if (!parseInt(page)) {
      return;
    }

    try {
      setLoading(true);

      const res = await api.get(`admin/image-section?page=${page}`, {
        params: filters,
      });

      const { sections } = res.data;

      sections.links?.shift();
      sections.links?.splice(sections.links.length - 1, 1);

      setSections(sections);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const newBanner = () => {
    setBannerId(0);
  };

  useEffect(() => {
    doSearch();
  }, []);

  return (
    <>
      <header className="row my-3">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <Filters
                filters={filters}
                setFilters={setFilters}
                onSave={() => {
                  doSearch();
                }}
                onClear={() => {
                  setFilters(DEFAULT_FILTERS);
                }}
              />
            </div>
          </div>
        </div>
      </header>

      <main className="card">
        <section className="card-body">
          <div className="container-fluid p-3">
            <header>
              <h1 className="h2 m-0">{util.t('IMAGE_SECTIONS')}</h1>
              <p className="mb-5">
                As seções cadastradas vão ser listadas na página principal da loja.
              </p>
            </header>

            <div className="text-right">
              {sections?.data?.length < 3 && (
                <Button onClick={newBanner} className="my-3" color="primary" outline>
                  {util.t('ADD')}
                </Button>
              )}
            </div>

            <ImageSectionTable
              onOpen={(id) => {
                setBannerId(id);
              }}
              loading={loading}
              sections={sections}
              doUpdate={doSearch}
            />

            <div className="d-flex justify-content-end mt-4">
              {sections && <Pagination onChange={(page) => doSearch(page)} {...sections} />}
            </div>
          </div>
        </section>

        <ImageSectionModal
          onToggle={() => {
            setBannerId(null);
          }}
          id={bannerId}
          onClose={() => {
            setBannerId(null);
          }}
          onSave={() => {
            doSearch();
          }}
        />
      </main>
    </>
  );
}

export default Sections;
