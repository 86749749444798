import React, { useState, useEffect } from 'react';

import { Button } from 'reactstrap';

import CatalogueModal from '~/components/CatalogueModal';
import CataloguesTable from '~/components/CataloguesTable';

import api from '~/services/api';

import util from '~/assets/util';

let cataloguesBaseState = [];

function Catalogues() {
  const [loading, setLoading] = useState(true);
  const [catalogues, setCatalogues] = useState(cataloguesBaseState);

  const [catalogueId, setCatalogueId] = useState(null);

  const doSearch = async (page: any = 1) => {
    if (!parseInt(page)) {
      return;
    }

    try {
      setLoading(true);

      const res = await api.get('admin/catalogues');

      const { catalogues } = res.data;

      cataloguesBaseState = [...catalogues];

      setCatalogues(catalogues);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const newCatalogue = () => {
    setCatalogueId(0);
  };

  useEffect(() => {
    doSearch();
  }, []);

  return (
    <>
      <main className="card my-3">
        <section className="card-body">
          <div className="container-fluid p-3">
            <header>
              <h1 className="h2 m-0">{util.t('CATALOGUES')}</h1>
              <p>{util.t('CATALOGUES_DESCRIPTION')}</p>
            </header>

            <div className="text-right">
              <Button
                disabled={catalogues?.length >= 7}
                onClick={newCatalogue}
                className="my-3"
                color="primary"
                outline>
                {util.t('ADD')}
              </Button>
            </div>

            <CataloguesTable
              loading={loading}
              setLoading={setLoading}
              catalogues={catalogues}
              setCatalogues={setCatalogues}
              doUpdate={doSearch}
              onOpen={(id) => {
                setCatalogueId(id);
              }}
            />
          </div>
        </section>

        <CatalogueModal
          id={catalogueId}
          onToggle={() => {
            setCatalogueId(null);
          }}
          onClose={() => {
            setCatalogueId(null);
          }}
          onSave={() => {
            doSearch();
          }}
          defaultPosition={catalogues.length + 1}
        />
      </main>
    </>
  );
}

export default Catalogues;
