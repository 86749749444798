import React, { useEffect, useState } from 'react';

import { Button, Card, CardBody, CardHeader, Modal } from 'reactstrap';

import BannersTable from '~/components/BannersTable';
import Pagination from '~/components/Pagination';
import Form from './Form';
import Filters from './Filters';

import api from '~/services/api';

import util from '~/assets/util';

const DEFAULT_FILTERS = {
  active: '',
  title: '',
};

function Banners() {
  const [loading, setLoading] = useState(true);
  const [banners, setBanners] = useState(null);

  const [filters, setFilters]: any = useState(DEFAULT_FILTERS);

  const [bannerId, setBannerId] = useState(null);

  const doSearch = async (page: any = 1) => {
    if (!parseInt(page)) {
      return;
    }

    try {
      setLoading(true);

      const res = await api.get(`admin/carousel?page=${page}`, {
        params: filters,
      });

      const { banners } = res.data;

      banners.links?.shift();
      banners.links?.splice(banners.links.length - 1, 1);

      setBanners(banners);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const newBanner = () => {
    setBannerId(0);
  };

  useEffect(() => {
    doSearch();
  }, []);

  return (
    <>
      <header className="row my-3">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <Filters
                filters={filters}
                setFilters={setFilters}
                onSave={() => {
                  doSearch();
                }}
                onClear={() => {
                  setFilters(DEFAULT_FILTERS);
                }}
              />
            </div>
          </div>
        </div>
      </header>

      <main className="card">
        <section className="card-body">
          <div className="container-fluid p-3">
            <header>
              <h1 className="h2 m-0">{util.t('BANNERS')}</h1>
            </header>

            <div className="text-right">
              <Button onClick={newBanner} className="my-3" color="primary" outline>
                {util.t('ADD')}
              </Button>
            </div>

            <BannersTable
              onOpen={(id) => {
                setBannerId(id);
              }}
              loading={loading}
              banners={banners}
              doUpdate={doSearch}
            />

            <div className="d-flex justify-content-end mt-4">
              {banners && <Pagination onChange={(page) => doSearch(page)} {...banners} />}
            </div>
          </div>
        </section>

        <Modal
          autoFocus={false}
          isOpen={bannerId != null}
          toggle={() => {
            setBannerId(null);
          }}
          size="xl">
          <Card>
            <CardHeader>{util.t('BANNER')}</CardHeader>
            <CardBody>
              <Form
                id={bannerId}
                onClose={() => {
                  setBannerId(null);
                }}
                onSave={() => {
                  doSearch();
                }}
              />
            </CardBody>
          </Card>
        </Modal>
      </main>
    </>
  );
}

export default Banners;
