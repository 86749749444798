import React from 'react';

import { Editor } from '@tinymce/tinymce-react';

import TinyMCEConfig from '~/config/tiny-mce';

interface TinyMCEProps {
  config?: any | null;
  onInit: (evt: any, editor: any) => void;
}

const apiKey = process.env.REACT_APP_TINY_API_KEY;

function TinyMCE({ onInit, config = null }: TinyMCEProps) {
  return <Editor apiKey={apiKey} onInit={onInit} init={config || TinyMCEConfig} />;
}

export default TinyMCE;
