import React, { memo, useState } from 'react';

import { FormGroup, Row, Col, Label, Input } from 'reactstrap';

import Button from '~/components/Button';
import SearchBrandInput from '~/components/Brands/SearchBrandInput';
import SearchAuthorInput from '~/components/Authors/SearchAuthorInput';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

interface FormProps {
  onSave: any;
}

function Form({ onSave }: FormProps) {
  const [saving, setSaving] = useState(false);

  const [rules, setRules] = useState({
    discount_type: '',
    author_id: '',
    brand_id: [],
    discount_percent: '',
    expires_at: '',
    starts_at: util.getCurrentDate(),
  });

  const handleChangeSelect = (ev) => {
    if (!ev) {
      return;
    }

    const name = ev.name;
    const value = ev.value;

    if (value == 'author') {
      rules.brand_id = [];
    } else if (value == 'brand') {
      rules.author_id = '';
    }

    setRules({ ...rules, [name]: ev.value });
  };

  const handleChangeBrands = (brands) => {
    brands && setRules({ ...rules, brand_id: brands });
  };

  const handleChange = (ev) => {
    const name = ev.target.name;

    setRules({ ...rules, [name]: ev.target.value });
  };

  const doSave = async (ev) => {
    ev.preventDefault();

    if (saving) {
      return false;
    }

    setSaving(true);

    try {
      const form = {
        ...rules,
        brand_id: rules.brand_id.map((option) => option.value),
      };

      const url = 'admin/products/rule/add';

      const res = await api.post(url, form);

      const data = res?.data;

      if (data.status) {
        onSave();

        return notification.$s(util.t('DISCOUNT_CREATED'));
      }

      notification.$e(util.t('DISCOUNT_CREATED_ERROR'));
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <form onSubmit={doSave} className="container-fluid p-3">
        <fieldset>
          <Row form>
            <Col lg="2">
              <FormGroup>
                <Label htmlFor="discount_type">{util.t('DISCOUNT_TYPE')}</Label>
                <FormGroup check inline>
                  <Input
                    id="discount_type-brand"
                    type="radio"
                    name="discount_type"
                    value="brand"
                    onChange={(e) => handleChangeSelect(e.target)}
                  />
                  <Label check htmlFor="discount_type-brand">
                    {util.t('BRAND')}
                  </Label>
                </FormGroup>

                <FormGroup check inline>
                  <Input
                    id="discount_type-author"
                    type="radio"
                    name="discount_type"
                    value="author"
                    onChange={(e) => handleChangeSelect(e.target)}
                  />
                  <Label check htmlFor="discount_type-author">
                    {util.t('AUTHOR')}
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
            {rules.discount_type == 'author' || rules.discount_type == 'brand' ? (
              <>
                <Col lg="3">
                  {rules.discount_type == 'author' && (
                    <FormGroup>
                      <Label htmlFor="author">{util.t('AUTHORS')}</Label>
                      <SearchAuthorInput onChange={handleChangeSelect} canCreate={false} />
                    </FormGroup>
                  )}

                  {rules.discount_type == 'brand' && (
                    <FormGroup>
                      <Label htmlFor="brand">{util.t('BRANDS')}</Label>

                      <SearchBrandInput isMulti onChange={handleChangeBrands} canCreate={false} />
                    </FormGroup>
                  )}
                </Col>
                <Col lg="2">
                  <FormGroup>
                    <Label htmlFor="discount_percent">{util.t('DISCOUNT_PERCENTAGE')}</Label>
                    <Input
                      className="form-control"
                      onChange={handleChange}
                      type="number"
                      step="0.01"
                      min={0.1}
                      max={100}
                      name="discount_percent"
                      id="discount_percent"
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg="2">
                  <FormGroup>
                    <Label htmlFor="starts_at">{util.t('STARTS_DATE')}</Label>
                    <Input
                      className="form-control"
                      onChange={handleChange}
                      value={rules.starts_at}
                      type="date"
                      min={util.getCurrentDate()}
                      name="starts_at"
                      id="starts_at"
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg="2">
                  <FormGroup>
                    <Label htmlFor="expires_at">{util.t('EXPIRATION_DATE')}</Label>
                    <Input
                      className="form-control"
                      onChange={handleChange}
                      type="date"
                      min={util.getCurrentDate()}
                      name="expires_at"
                      id="expires_at"
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg="1">
                  <FormGroup>
                    <Label>{util.t('ACTION')}</Label>
                    <footer className="d-flex justify-content-start">
                      <Button color="primary" type="submit" loading={saving}>
                        {util.t('SAVE')}
                      </Button>
                    </footer>
                  </FormGroup>
                </Col>
              </>
            ) : null}
          </Row>
        </fieldset>
        <hr />
      </form>
    </>
  );
}

export default memo(Form);
