import React, { memo } from 'react';

import debounce from 'lodash.debounce';

import { Row, FormGroup, Input, Label, Col, Form } from 'reactstrap';

import Button from '~/components/Button';
import SearchBrandInput from '~/components/Brands/SearchBrandInput';
import SearchCategoryInput from '~/components/Category/SearchCategoryInput';

import api from '~/services/api';

import util from '~/assets/util';

interface FiltersProps {
  filters: any;
  setFilters: any;
  onSave: any;
  onClear: any;
}

function Filters({ filters, setFilters, onSave, onClear }: FiltersProps) {
  const doSubmit = (ev) => {
    ev.preventDefault();

    onSave();
  };

  const handleChange = (ev) => {
    const name = ev.target.name;

    setFilters({ ...filters, [name]: ev.target.value });
  };

  const handleChangeSelect = (ev, { name }) => {
    if (name == 'brand_id') {
      setFilters({ ...filters, brand_id: ev?.value, selectedOptionBrand: ev });
    } else {
      setFilters({ ...filters, category_id: ev?.category_id, selectedOptionCategory: ev });
    }
  };

  async function loadBrandDataOptions(inputValue) {
    try {
      const res = await api.get(`admin/products/brands?q=${inputValue}`);

      const { brands } = res?.data;

      if (!brands?.length) {
        return [];
      }

      const options = brands?.map(
        (item) => new Object({ name: 'brand_id', value: item.brand_id, label: item.name }),
      );

      return options?.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
    } catch (e) {
      console.error(e);
    }
  }

  const loadBrands = (inputValue) =>
    new Promise((resolve) => {
      resolve(loadBrandDataOptions(inputValue));
    });

  const debouncedLoadBrands = debounce((inputValue, callback) => {
    inputValue.length > 2 && loadBrands(inputValue).then((options) => callback(options));
  }, 1000);

  return (
    <Form onSubmit={doSubmit}>
      <Row form>
        <FormGroup className="col-md-2">
          <Label htmlFor="filter_name">{util.t('TITLE')}</Label>
          <Input
            id="filter_name"
            name="name"
            type="text"
            onChange={handleChange}
            value={filters.name}
          />
        </FormGroup>

        <FormGroup className="col-md-2">
          <Label htmlFor="filter_isbn">{util.t('ISBN')}</Label>
          <Input
            id="filter_isbn"
            name="isbn"
            type="text"
            onChange={handleChange}
            value={filters.isbn}
          />
        </FormGroup>

        <FormGroup className="col-md-3">
          <Label htmlFor="filter_category">{util.t('CATEGORY')}</Label>

          <SearchCategoryInput
            id="filter_category"
            value={filters.selectedOptionCategory}
            onChange={handleChangeSelect}
            showTypeSelect={false}
          />
        </FormGroup>

        <FormGroup className="col-md-3">
          <Label htmlFor="filter_brands">{util.t('BRANDS')}</Label>

          <SearchBrandInput
            name="brand_id"
            value={filters.selectedOptionBrand}
            onChange={handleChangeSelect}
            loadOptions={debouncedLoadBrands}
          />
        </FormGroup>

        <div className="col-md-3">
          <FormGroup>
            <Label for="sort_by">{util.t('ORDER_BY')}</Label>
            <Input
              id="sort_by"
              type="select"
              value={filters.sort_by_discount}
              onChange={(e) => setFilters({ ...filters, sort_by_discount: e.target.value })}>
              <option value="" disabled>
                {util.t('SELECT')}
              </option>
              <option value="desc">{util.t('BIGGER_DISCOUNT')}</option>
              <option value="asc">{util.t('LOWER_DISCOUNT')}</option>
            </Input>
          </FormGroup>
        </div>
      </Row>

      <Row form>
        <Col>
          <Button type="submit" color="primary">
            {util.t('SEARCH')}
          </Button>
          <Button className="ml-1" onClick={onClear} type="button" color="secondary">
            {util.t('CLEAR')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default memo(Filters);
