import React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Banners from './pages/Banners';
import Catalogues from './pages/Catalogues';
import Categories from './pages/Categories';
import Coupons from './pages/Coupons';
import Dashboard from './pages/Dashboard';
import Discounts from './pages/Discounts';
import General from './pages/General';
import Hosts from './pages/Hosts';
import Integration from './pages/Integration';
import Login from '~/pages/Login';
import Orders from './pages/Orders';
import PagSeguroConnect from './pages/PagSeguroConnect';
import Policies from './pages/Policies';
import Product from './pages/Products/new';
import Products from './pages/Products';
import ProductsPage from './pages/ProductsPages/new';
import ProductsPages from './pages/ProductsPages';
import Reports from './pages/Reports';
import SchoolLists from './pages/SchoolLists';
import Sections from './pages/Sections';
import Settings from './pages/Settings';
import Store from './pages/Stores/new';
import Stores from './pages/Stores';
import StyleEditor from './pages/StyleEditor';
import Users from './pages/Users';

const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="/" element={<Dashboard />}>
          <Route path="/" index element={<Orders />} />
          <Route path="banners" element={<Banners />} />
          <Route path="catalogues" element={<Catalogues />} />
          <Route path="categories" element={<Categories />} />
          <Route path="coupons" element={<Coupons />} />
          <Route path="discounts" element={<Discounts />} />
          <Route path="general" element={<General />} />
          <Route path="home" element={<Orders />} />
          <Route path="hosts" element={<Hosts />} />
          <Route path="integration" element={<Integration />} />
          <Route path="orders" element={<Orders />} />
          <Route path="policies" element={<Policies />} />
          <Route path="products-pages" element={<ProductsPages />} />
          <Route path="products-pages/edit/:id" element={<ProductsPage />} />
          <Route path="products-pages/new" element={<ProductsPage />} />
          <Route path="products" element={<Products />} />
          <Route path="products/edit/:id" element={<Product />} />
          <Route path="products/new" element={<Product />} />
          <Route path="reports" element={<Reports />} />
          <Route path="school-lists" element={<SchoolLists />} />
          <Route path="sections" element={<Sections />} />
          <Route path="settings" element={<Settings />} />
          <Route path="settings/pagseguro-connect/" element={<PagSeguroConnect />} />
          <Route path="stores" element={<Stores />} />
          <Route path="stores/edit/:id" element={<Store />} />
          <Route path="stores/new" element={<Store />} />
          <Route path="style-editor" element={<StyleEditor />} />
          <Route path="users" element={<Users />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponent;
