import React from 'react';

import { Modal as BModal, Card, CardBody, CardHeader } from 'reactstrap';

import Form from './Form';

import util from '~/assets/util';

interface ModalProps {
  isOpen: boolean;
  onToggle: () => void;
  onClose: () => void;
  onSave: (data) => void;
  enablePositionField?: boolean;
  enableStatusField?: boolean;
  defaultPosition?: any;
  [key: string]: any;
}

function Modal({ isOpen, onToggle, onClose, onSave, defaultPosition = 1, ...props }: ModalProps) {
  return (
    <BModal autoFocus={false} isOpen={isOpen} toggle={onToggle} size="xl" {...props}>
      <Card>
        <CardHeader>{util.t('ADD_LIST')}</CardHeader>
        <CardBody>
          <Form onClose={onClose} onSave={onSave} />
        </CardBody>
      </Card>
    </BModal>
  );
}

export default Modal;
