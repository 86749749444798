import React, { memo, useCallback, useMemo, useState } from 'react';

import { FaSearchPlus } from 'react-icons/fa';
import { Button, Spinner, Table } from 'reactstrap';

import DeleteButton from '../DeleteButton';

import util from '~/assets/util';

import api from '~/services/api';
import notification from '~/services/notification';

import './index.scss';

interface UsersTableProps {
  users: any;
  loading?: any;
  onOpen: any;
  doUpdate: any;
}

function UsersTable({ users, loading = false, onOpen, doUpdate }: UsersTableProps) {
  const [deleting, setDeleting] = useState([]);

  const doDelete = useCallback(
    async (id) => {
      try {
        setDeleting(deleting.concat([id]));

        const url = `admin/users/delete/${id}`;

        const res = await api.delete(url);

        const data = res?.data;

        if (data.result) {
          notification.$s(util.t('USER_DELETED'));

          doUpdate();
        }
      } catch (e) {
        notification.$e(e);
      } finally {
        setDeleting(deleting.filter((deletingId) => deletingId != id));
      }
    },
    [deleting, doUpdate],
  );

  const list = useMemo(
    () =>
      users?.data?.map((user) => (
        <tr key={user.user_id}>
          <td>{user.name}</td>
          <td>{user.cpf || user.cnpj}</td>
          <td>{user.email}</td>
          <td className="d-flex align-center justify-content-end">
            <Button
              onClick={() => onOpen(user.user_id)}
              size="sm"
              className="mr-1"
              outline
              color="info">
              <FaSearchPlus />
            </Button>

            <DeleteButton
              onDelete={() => doDelete(user.user_id)}
              loading={deleting.indexOf(user.user_id) > -1}
              size="sm"
            />
          </td>
        </tr>
      )),
    [users, deleting, onOpen, doDelete],
  );

  const renderBody = () => {
    if (loading) {
      return (
        <tr>
          <th className="text-center w-100" colSpan={6}>
            <Spinner size="sm" color="primary" />
          </th>
        </tr>
      );
    }

    if (!users?.data?.length) {
      return (
        <tr>
          <td className="text-center" colSpan={4}>
            <span>{util.t('NO_RECORDS_FOUND')}</span>
          </td>
        </tr>
      );
    }

    return list;
  };

  return (
    <>
      <Table hover responsive>
        <thead className="thead-light">
          <tr>
            <th>{util.t('NAME')}</th>
            <th>{util.t('CPF_CNPJ')}</th>
            <th>{util.t('EMAIL')}</th>
            <th className="text-right">{util.t('ACTION')}</th>
          </tr>
        </thead>

        <tbody>{renderBody()}</tbody>
      </Table>
    </>
  );
}

export default memo(UsersTable);
