import React, { memo, useEffect, useState } from 'react';

import { FaCheck, FaPlug, FaTimes } from 'react-icons/fa';
import { Badge, Button, Spinner } from 'reactstrap';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

const ReactSwal = withReactContent(Swal);
interface PagSeguroConnectButtonProps {
  disabled: any;
  className?: string;
  [key: string]: any;
}

function PagSeguroConnectButton(props: PagSeguroConnectButtonProps) {
  const [connected, setConnected] = useState(null);
  const [link, setLink] = useState(null);
  const [loading, setLoading] = useState(true);
  const [disconnecting, setDisconnecting] = useState(false);

  const fetchStatus = async () => {
    try {
      const url = 'admin/pagseguro-connect';
      const res = await api.get(url);

      const { connected, link } = res.data;

      setConnected(connected);
      setLink(link);
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const confirmDisconnect = () => {
    ReactSwal.fire({
      html: util.t('CONFIRM_DISCONNECT_PAGSEGURO'),
      showCancelButton: true,
      cancelButtonText: <FaTimes />,
      confirmButtonText: <FaCheck />,
      confirmButtonColor: '#df4759',
    }).then((result) => {
      if (result.isConfirmed) {
        doDisconnect();
      }
    });
  };

  const doDisconnect = async () => {
    setDisconnecting(true);

    try {
      const url = 'admin/pagseguro-connect/disconnect';

      await api.post(url);

      notification.$s(util.t('PAGSEGURO_DISCONNECTED'));
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      fetchStatus();
      setDisconnecting(false);
    }
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  if (loading) {
    return (
      <Badge color="info" {...props}>
        <Spinner size="sm" /> {util.t('VERIFYING_PAGSEGURO_CONNECTION')}
      </Badge>
    );
  }

  if (connected) {
    return (
      <>
        <Badge color="success" {...props} className={`${props.className || ''} mr-2`}>
          <FaCheck /> {util.t('CONNECTED_TO_PAGSEGURO')}
        </Badge>

        <Button
          color="danger"
          disabled={props.disabled}
          size="sm"
          type="button"
          onClick={confirmDisconnect}
          {...props}>
          {disconnecting ? (
            <Spinner size="sm" />
          ) : (
            <>
              <FaPlug /> {util.t('DISCONNECT')}
            </>
          )}
        </Button>
      </>
    );
  }

  return (
    <div className="alert alert-warning" role="alert">
      {util.t('LINK_YOUR_PAGSEGURO_ACCOUNT_TO_UPGRADE')}
      <br />
      <Button
        tag="a"
        color="warning"
        href={link}
        target="_blank"
        disabled={props.disabled}
        {...props}>
        <FaPlug /> {util.t('CONNECT')}
      </Button>
    </div>
  );
}

export default memo(PagSeguroConnectButton);
