import React, { useState, useEffect, useRef, memo } from 'react';

import { Row, Col, Label, Input, Spinner, FormGroup } from 'reactstrap';

import { useNavigate } from 'react-router-dom';

import api from '~/services/api';
import notification from '~/services/notification';

import Button from '~/components/Button';
import ImageInput from '~/components/ImageInput';
import TinyMCE from '~/components/TinyMCE';

import BlocksManager, { PageBlock } from './BlocksManager';

import util from '~/assets/util';

interface ProductPageFormProps {
  id: number | string | null;
}

interface ProductPageData {
  products_page_id: number | null;
  name: string;
  slug: string;
  description: string;
  blocks: PageBlock[];
  banner_url?: string;
}

function Form({ id }: ProductPageFormProps) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const bannerFileRef = useRef<File | null>(null);

  const [formData, setFormData] = useState<ProductPageData>({
    products_page_id: null,
    name: '',
    slug: '',
    description: '',
    blocks: [],
    banner_url: '',
  });

  const descriptionEditorRef = useRef(null);
  const formRef = useRef<HTMLFormElement>(null);

  function handleChangeBanner(previewUrl: string, file: File) {
    setFormData((prev) => ({ ...prev, banner_url: previewUrl }));
    bannerFileRef.current = file;
  }

  function handleChange(ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { name, value } = ev.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  }

  async function doSave(ev: React.FormEvent) {
    ev.preventDefault();

    if (!formData.blocks.length) {
      return notification.$e(util.t('ADD_AT_LEAST_ONE_BLOCK'));
    }

    setSaving(true);

    try {
      const form = new FormData();

      const blocks = formData.blocks.map((block) => {
        return {
          id: block.id,
          type: block.type,
          catalogue_id: block.catalogueId,
          image_section_id: block.imageSectionId,
        };
      });

      form.append('blocks', JSON.stringify(blocks));
      form.append('description', descriptionEditorRef.current.getContent());
      form.append('name', formData.name);
      form.append('slug', formData.slug);

      if (bannerFileRef.current) {
        form.append('banner', bannerFileRef.current);
      }

      const headers = { 'Content-Type': 'multipart/form-data' };

      const url = id ? `/admin/products-pages/${id}` : '/admin/products-pages';

      await api.post(url, form, { headers });

      notification.$s(util.t('PAGE_SAVED_SUCCESS'));

      navigate('/products-pages');
    } catch (err) {
      notification.$e(err);
      console.error(err);
    } finally {
      setSaving(false);
    }
  }

  const onInitTinyMCE = (_evt, editor) => {
    descriptionEditorRef.current = editor;

    if (formData.description) {
      setTimeout(() => {
        editor.setContent(formData.description);
      }, 0);
    }
  };

  useEffect(() => {
    async function loadPage() {
      setLoading(true);

      try {
        const res = await api.get(`/admin/products-pages/${id}`);
        const data = res.data;

        const blocks = data.blocks.map((block: any) => {
          const data: PageBlock = {
            key: block.products_page_block_id?.toString(),
            id: block.products_page_block_id,
            type: block.type,
            catalogueId: block.catalogue_id,
            imageSectionId: block.image_section_id,
          };

          if (block.type === 'image-section') {
            data.title = block.image_section.title;
            data.firstImage = block.image_section.first_image;
            data.secondImage = block.image_section.second_image;
          } else {
            data.title = block.catalogue.name;
            data.items = block.catalogue.items;
          }

          return data;
        });

        setFormData({
          blocks,
          products_page_id: data.id,
          name: data.name,
          slug: data.slug,
          description: data.description || '',
          banner_url: data.banner_url || '',
        });
      } catch (err) {
        navigate('/products-pages');

        console.error(err);
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      loadPage();
      return;
    }

    setFormData({
      products_page_id: null,
      name: '',
      slug: '',
      description: '',
      blocks: [],
      banner_url: '',
    });
  }, [id]);

  if (loading) {
    return (
      <div className="p-5">
        <Spinner size="lg" className="d-block m-auto" color="primary" />
      </div>
    );
  }

  return (
    <form ref={formRef} onSubmit={doSave} className="container-fluid p-3">
      <fieldset>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label for="name">{util.t('NAME')}</Label>
              <Input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label for="slug">{util.t('SLUG')}</Label>
              <Input
                type="text"
                id="slug"
                name="slug"
                value={formData.slug}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <FormGroup>
              <Label onClick={() => descriptionEditorRef.current?.focus()}>
                {util.t('DESCRIPTION')}
              </Label>

              <TinyMCE onInit={onInitTinyMCE} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <FormGroup>
              <Label>{util.t('BANNER')}</Label>
              <ImageInput
                id="banner"
                name="banner"
                src={formData.banner_url || ''}
                alt={util.t('BANNER')}
                onChange={handleChangeBanner}
              />
            </FormGroup>
          </Col>
        </Row>
      </fieldset>

      <hr />

      <BlocksManager
        blocks={formData.blocks}
        onChangeBlocks={(blocks) => setFormData((prev) => ({ ...prev, blocks }))}
      />

      <hr />

      <footer className="d-flex justify-content-end">
        <Button type="submit" color="primary" loading={saving}>
          {util.t('SAVE')}
        </Button>
      </footer>
    </form>
  );
}

export default memo(Form);
