import React, { useCallback, useEffect, useState } from 'react';

import { FaPlusCircle, FaFile, FaFileExcel } from 'react-icons/fa';

import { Button, Card, CardBody, CardHeader, Modal } from 'reactstrap';

import Form from './Form';
import Upload from './Upload';
import Filters from './Filters';
import UsersTable from '~/components/UsersTable';
import Pagination from '~/components/Pagination';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

import './index.scss';

const DEFAULT_FILTERS = {
  role: '',
  name: '',
  email: '',
  cpf: '',
};

function Users() {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [loadingReport, setLoadingReport] = useState(false);

  const [filters, setFilters]: any = useState(DEFAULT_FILTERS);
  const [userId, setUserId] = useState(null);
  const [importUsers, setImportUsers] = useState(false);

  const doSearch = useCallback(
    async (page: any = 1) => {
      if (!parseInt(page)) {
        return;
      }

      try {
        setLoading(true);

        const res = await api.get(`admin/users?page=${page}`, { params: filters });

        const { users } = res.data;

        users.links?.shift();
        users.links?.splice(users.links.length - 1, 1);

        setUsers(users);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [filters, setUsers],
  );

  const newUser = () => {
    setUserId(0);
  };

  const createReport = async () => {
    try {
      setLoadingReport(true);

      const res = await api.post('admin/users/export');

      const { path } = res.data;

      window.location.href = path;
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setLoadingReport(false);
    }
  };

  useEffect(() => {
    doSearch();
  }, []);

  return (
    <>
      <header className="row mt-3">
        <div className="col-12">
          <div className="card p-3">
            <Filters
              filters={filters}
              setFilters={setFilters}
              onSave={doSearch}
              onClear={() => {
                setFilters(DEFAULT_FILTERS);
              }}
            />
          </div>
        </div>
      </header>

      <main className="card my-3">
        <section className="card-body">
          <div className="container-fluid p-3">
            <header>
              <h1 className="h2 m-0">{util.t('USERS')}</h1>
            </header>

            <div className="text-right">
              <Button
                disabled={!users?.data?.length}
                loading={loadingReport}
                onClick={createReport}
                className="m-3"
                color="primary"
                outline>
                <FaFileExcel className="mr-1" />
                {util.t('EXPORT')}
              </Button>

              <Button
                onClick={() => setImportUsers(true)}
                className="mr-3 align-items-center"
                color="primary"
                outline>
                <FaFile className="mr-1" />
                {util.t('IMPORT_CPF')}
              </Button>

              <Button onClick={newUser} className="my-3 align-items-center" color="primary" outline>
                <FaPlusCircle className="mr-1" />
                {util.t('ADD')}
              </Button>
            </div>

            <UsersTable
              onOpen={(id) => {
                setUserId(id);
              }}
              loading={loading}
              users={users}
              doUpdate={doSearch}
            />

            <div className="d-flex justify-content-end mt-4">
              {users && <Pagination onChange={(page) => doSearch(page)} {...users} />}
            </div>
          </div>
        </section>

        <Modal autoFocus={false} isOpen={userId != null} toggle={() => setUserId(null)} size="xl">
          <Card>
            <CardHeader>{util.t('USER')}</CardHeader>
            <CardBody>
              <Form
                id={userId}
                onClose={() => {
                  setUserId(null);
                }}
                onSave={() => {
                  doSearch();
                }}
              />
            </CardBody>
          </Card>
        </Modal>

        <Modal
          autoFocus={false}
          isOpen={importUsers}
          toggle={() => setImportUsers(false)}
          size="md">
          <Card>
            <CardHeader>{util.t('IMPORT_CPF')}</CardHeader>
            <CardBody>
              <Upload
                onClose={() => {
                  setImportUsers(false);
                }}
                onSave={() => {
                  doSearch();
                }}
              />
            </CardBody>
          </Card>
        </Modal>
      </main>
    </>
  );
}

export default Users;
