import React, { memo } from 'react';

import { FaCheck, FaTimes, FaTrash } from 'react-icons/fa';
import { Button, Spinner } from 'reactstrap';

import Swal from 'sweetalert2';
import util from '~/assets/util';

import withReactContent from 'sweetalert2-react-content';

const ReactSwal = withReactContent(Swal);

interface DeleteButtonProps {
  onDelete: any;
  loading?: any;
  disabled?: any;
  [key: string]: any;
}

function DeleteButton({
  onDelete,
  loading = false,
  disabled = false,
  ...props
}: DeleteButtonProps) {
  const showAlert = () => {
    if (disabled) {
      return;
    }

    ReactSwal.fire({
      html: util.t('CONFIRM_DELETE'),
      showCancelButton: true,
      cancelButtonText: <FaTimes />,
      confirmButtonText: <FaCheck />,
      confirmButtonColor: '#df4759',
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete();
      }
    });
  };

  const renderIcon = () => {
    if (loading) {
      return <Spinner color="danger" size="sm" />;
    }

    return <FaTrash color="danger" />;
  };

  return (
    <div onClick={showAlert} role="button">
      <Button disabled={!!disabled} {...props} color="danger" outline>
        {renderIcon()}
      </Button>
    </div>
  );
}

export default memo(DeleteButton);
