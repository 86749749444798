import React, { memo, useState } from 'react';

import debounce from 'lodash.debounce';

import AsyncSelect from 'react-select/async';

import { Button, InputGroup, InputGroupAddon, UncontrolledTooltip } from 'reactstrap';

import { FaPlus } from 'react-icons/fa';

import AuthorModal from '../AuthorModal';

import api from '~/services/api';

import util from '~/assets/util';

interface SearchAuthorInputProps {
  canCreate?: any;
  onChange?: any;
  placeholder?: string;
  innerRef?: any;
  onCreate?: any;
  [key: string]: any;
}

function SearchAuthorInput({
  canCreate = true,
  onChange,
  placeholder = 'SEARCH_THREE_DOTS',
  innerRef = null,
  ...props
}: SearchAuthorInputProps) {
  const [showModal, setShowModal] = useState(false);

  async function loadDataOptions(inputValue) {
    try {
      const res = await api.get(`admin/authors/search?q=${inputValue}`);

      const { authors } = res?.data;

      if (!authors) {
        return [];
      }

      return authors?.map((item) => {
        return new Object({ name: 'author_id', value: item.author_id, label: item.name });
      });
    } catch (e) {
      console.error(e);
    }
  }

  const loadAuthors = (inputValue) =>
    new Promise((resolve) => {
      resolve(loadDataOptions(inputValue));
    });

  const debouncedLoadAuthors = debounce((inputValue, callback) => {
    inputValue.length > 2 && loadAuthors(inputValue).then((options) => callback(options));
  }, 1000);

  const handleNewAuthor = (author) => {
    props.onCreate && props.onCreate(author);

    setShowModal(false);
  };

  return (
    <>
      <InputGroup>
        <AsyncSelect
          {...props}
          ref={innerRef}
          className="react-select-full-width"
          cacheOptions
          loadOptions={debouncedLoadAuthors}
          isClearable
          noOptionsMessage={() => util.t('NO_OPTION')}
          placeholder={util.t(placeholder)}
          loadingMessage={() => util.t('SEARCHING_THREE_DOTS')}
          onChange={onChange}
        />

        {canCreate && (
          <InputGroupAddon addonType="append">
            <Button
              id="new-author-button"
              alt={util.t('NEW_AUTHOR')}
              type="button"
              onClick={() => setShowModal(true)}
              size="sm"
              color="primary">
              <FaPlus />
            </Button>

            <UncontrolledTooltip target="new-author-button">
              {util.t('NEW_AUTHOR')}
            </UncontrolledTooltip>
          </InputGroupAddon>
        )}
      </InputGroup>

      <AuthorModal
        id={0}
        isOpen={showModal}
        onSave={handleNewAuthor}
        onToggle={() => setShowModal(false)}
      />
    </>
  );
}

export default memo(SearchAuthorInput);
