import React, { useState, useEffect, useCallback } from 'react';

import { Button, Table, Spinner } from 'reactstrap';

import { FaEye } from 'react-icons/fa';

import { useSelector } from 'react-redux';

import DeleteButton from '~/components/DeleteButton';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';
import { Policies as PolicieConst } from '~/assets/constants';
import PoliciesModal from '~/components/PoliciesModal';

type Policie = {
  police_id: number;
  slug: string;
  tenant_id: number;
  text: string;
  title: string;
  type: string;
};

function Policies() {
  const [loading, setLoading] = useState(true);
  const [showPoliciesModal, setPoliciesModal] = useState(false);
  const [policiesList, setPoliciesList] = useState<Policie[] | null>(null);
  const [police, setPolice] = useState<Policie | null>(null);
  const tenant = useSelector((state: any) => state.tenant);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    try {
      setLoading(true);

      const url = '/admin/policies';

      const result = await api.get(url);

      setPoliciesList(result.data.policies);
    } catch (e) {
      notification.$e(e);
    } finally {
      setLoading(false);
    }
  };

  const doDelete = async (id) => {
    try {
      const url = `/admin/policies/delete/${id}`;

      await api.delete(url);

      notification.$s(util.t('DELETED_SUCCESSFULLY'));
    } catch (e) {
      notification.$e(e);
    } finally {
      load();
    }
  };

  const doEdit = (selectedPolicie) => {
    setPolice(selectedPolicie);
    setPoliciesModal(true);
  };

  const onClose = () => {
    setPolice(null);
    setPoliciesModal(false);
  };

  const viewPage = (police) => {
    if (!police || !tenant) return;

    const baseUrl = tenant.details.client_url.replace(/\/$/, '');
    let path = police.type;

    if (police.type == PolicieConst.POLICE) {
      path = PolicieConst.POLICIES;
    }

    const fullUrl = `${baseUrl}/${path}/${police.slug}`;
    window.open(fullUrl, '_blank');
  };

  const list = useCallback(() => {
    return policiesList?.map((police) => (
      <tr key={police.police_id}>
        <td>{police.slug}</td>
        <td>{police.title}</td>
        <td>{police.type == PolicieConst.POLICE ? util.t('POLICE') : util.t('PAGE_CONTENT')}</td>
        <td>
          <div className="d-flex align-center justify-content-end">
            <Button className="mr-1" onClick={() => doEdit(police)} size="sm" outline color="info">
              {util.t('EDIT')}
            </Button>
            <Button
              className="mr-1"
              size="sm"
              outline
              color="primary"
              onClick={() => viewPage(police)}>
              <FaEye />
            </Button>

            <DeleteButton onDelete={() => doDelete(police.police_id)} size="sm" loading={false} />
          </div>
        </td>
      </tr>
    ));
  }, [policiesList]);

  const renderBody = () => {
    if (loading) {
      return (
        <tr>
          <th className="text-store w-100" colSpan={3}>
            <Spinner size="sm" color="primary" className="d-block m-auto" />
          </th>
        </tr>
      );
    }

    if (policiesList?.length) {
      return list();
    }

    return (
      <tr>
        <td className="text-center" colSpan={5}>
          <span>{util.t('NO_RECORDS_FOUND')}</span>
        </td>
      </tr>
    );
  };

  return (
    <>
      <main className="card my-3">
        <section className="card-body">
          <div className="container-fluid p-3">
            <header>
              <h1 className="h2 m-0">{util.t('POLICIES')}</h1>
            </header>

            <div className="text-right">
              <Button
                className="my-3"
                onClick={() => setPoliciesModal(true)}
                color="primary"
                outline>
                {util.t('ADD')}
              </Button>
            </div>

            <Table hover responsive>
              <thead className="thead-light">
                <tr>
                  <th>{util.t('SLUG')}</th>
                  <th>{util.t('TITLE')}</th>
                  <th>{util.t('TYPE')}</th>
                  <th className="text-right">{util.t('ACTION')}</th>
                </tr>
              </thead>

              <tbody>{renderBody()}</tbody>
            </Table>
          </div>
        </section>

        <PoliciesModal onClose={onClose} isOpen={showPoliciesModal} load={load} police={police} />
      </main>
    </>
  );
}

export default Policies;
