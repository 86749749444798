import React, { memo, useEffect, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';

import { FaPen, FaPercent } from 'react-icons/fa';

import { format, parseISO } from 'date-fns';

import { Button, Spinner, Table } from 'reactstrap';

import DeleteButton from '../DeleteButton';

import util from '~/assets/util';

import './index.scss';

interface ProductsTableProps {
  products: any;
  loading?: any;
  onOpen?: any;
  onDelete?: any;
  enableDiscountColumns?: boolean;
  enableSchoolListColumns?: boolean;
  deleting: any[];
  rowKey?: string;
  isMultiDelete?: boolean;
  productsPage?: boolean;
}

function ProductsTable({
  products,
  loading = false,
  onOpen,
  onDelete,
  deleting,
  enableDiscountColumns = false,
  enableSchoolListColumns = false,
  rowKey = 'inventory_id',
  isMultiDelete = false,
  productsPage = false,
}: ProductsTableProps) {
  const [multiDelete, setMultiDelete] = useState([]);

  const handleCheckboxChange = (id: string | number) => {
    setMultiDelete((prev) =>
      prev.includes(id) ? prev.filter((item) => item != id) : [...prev, id],
    );
  };

  const handleSelectAll = () => {
    let allIds = [];

    if (productsPage) {
      allIds =
        products?.data
          ?.filter((product) => product.tenant_id !== null)
          .map((product) => product[rowKey]) ?? [];
    } else {
      allIds = products?.data?.map((product) => product[rowKey]) ?? [];
    }

    const areAllSelected = allIds.every((id) => multiDelete.includes(id));
    setMultiDelete(areAllSelected ? [] : allIds);
  };

  useEffect(() => {
    setMultiDelete([]);
  }, [products]);

  const list = useMemo(
    () =>
      products?.data?.map((product) => (
        <tr key={product[rowKey]}>
          {isMultiDelete && (
            <td>
              <input
                type="checkbox"
                disabled={product.tenant_id == null && productsPage}
                checked={multiDelete.includes(product[rowKey])}
                onChange={() => handleCheckboxChange(product[rowKey])}
              />
            </td>
          )}
          <td>
            <img
              className="img-fluid"
              width="30"
              src={product.thumbnailTenant ? product.thumbnailTenant : product.thumbnail}
              alt={product.name}
            />
          </td>
          <td width="20%">{product.name}</td>
          <td>{product.bar_code}</td>
          <td>{product.author_name}</td>
          <td>{product.brand_name}</td>
          <td width="10%">R$ {util.format(product.price)}</td>
          <td width="10%">
            {product.product_price > 0 ? 'R$ ' + util.format(product.product_price) : '-'}
          </td>
          {enableDiscountColumns && (
            <>
              <td>
                {parseFloat(product.percent).toLocaleString('pt-BR', {
                  minimumFractionDigits: product.percent % 1 === 0 ? 0 : 2,
                })}
                %
              </td>
              <td>{format(parseISO(product.expires_at), 'dd/MM/yyyy')}</td>
            </>
          )}
          {enableSchoolListColumns && (
            <>
              <td>{product.school_name}</td>
              <td>{product.serie_name}</td>
            </>
          )}
          <td>{product.stock}</td>
          <td>{product.company_name}</td>
          <td>
            <div className="d-flex align-center justify-content-end">
              {product.tenant_id === null ? (
                <Button
                  size="sm"
                  className="mr-1"
                  outline
                  color="info"
                  onClick={() => onOpen(product.inventory_id)}>
                  <FaPen />
                </Button>
              ) : (
                <>
                  <Link to={`/products/edit/${product.inventory_id}`}>
                    <Button size="sm" className="mr-1" outline color="info">
                      <FaPen />
                    </Button>
                  </Link>
                  <Button
                    size="sm"
                    className="mr-1"
                    outline
                    color="info"
                    onClick={() => onOpen(product.inventory_id)}>
                    <FaPercent />
                  </Button>
                </>
              )}
              {(!productsPage || product.tenant_id) && (
                <DeleteButton
                  onDelete={() => onDelete(product[rowKey])}
                  loading={deleting.indexOf(product[rowKey]) > -1}
                  size="sm"
                />
              )}
            </div>
          </td>
        </tr>
      )),
    [products, deleting, onOpen, onDelete, multiDelete],
  );

  const renderBody = () => {
    if (loading) {
      return (
        <tr>
          <th
            className="text-center w-100"
            colSpan={enableDiscountColumns || enableSchoolListColumns ? 12 : 10}>
            <Spinner size="sm" color="primary" />
          </th>
        </tr>
      );
    }

    if (products?.data?.length) {
      return list;
    }

    return (
      <tr>
        <td
          className="text-center"
          colSpan={enableDiscountColumns || enableSchoolListColumns ? 12 : 10}>
          <span>{util.t('NO_RECORDS_FOUND')}</span>
        </td>
      </tr>
    );
  };

  const floatingButton = () => {
    if (multiDelete.length <= 0) {
      return;
    }

    return (
      <div className="floatingButtons">
        <DeleteButton onDelete={() => onDelete(multiDelete)} size="sm" />
      </div>
    );
  };

  return (
    <>
      <Table hover responsive>
        <thead className="thead-light">
          <tr>
            {isMultiDelete && (
              <th>
                {productsPage ? (
                  <input
                    type="checkbox"
                    checked={
                      products?.data?.length > 0 &&
                      products.data.some((product) => product.tenant_id != null) &&
                      products.data
                        .filter((product) => product.tenant_id !== null)
                        .every((product) => multiDelete.includes(product[rowKey]))
                    }
                    onChange={handleSelectAll}
                  />
                ) : (
                  <input
                    type="checkbox"
                    checked={
                      products?.data?.length > 0 &&
                      products.data.every((product) => multiDelete.includes(product[rowKey]))
                    }
                    onChange={handleSelectAll}
                  />
                )}
              </th>
            )}
            <th>{util.t('IMAGE')}</th>
            <th>{util.t('TITLE')}</th>
            <th>{util.t('ISBN')}</th>
            <th>{util.t('AUTHOR')}</th>
            <th>{util.t('BRAND')}</th>
            <th>{util.t('ORIGINAL_VALUE')}</th>
            <th>{util.t('EDITED_VALUE')}</th>
            {enableDiscountColumns && (
              <>
                <th>{util.t('DISCOUNT')}</th>
                <th>{util.t('EXPIRATION_DATE')}</th>
              </>
            )}
            {enableSchoolListColumns && (
              <>
                <th>{util.t('SCHOOL')}</th>
                <th>{util.t('SERIE')}</th>
              </>
            )}
            <th>{util.t('QUANTITY_STOCK')}</th>
            <th>{util.t('DISTRIBUTION_CENTER')}</th>
            <th className="text-right">{util.t('ACTION')}</th>
          </tr>
        </thead>

        <tbody>{renderBody()}</tbody>
      </Table>
      {floatingButton()}
    </>
  );
}

export default memo(ProductsTable);
