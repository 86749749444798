import React, { memo, useState, useEffect } from 'react';

import { useRef } from 'react';

import {
  Card,
  CardBody,
  CardHeader,
  Modal,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';

import TinyMCE from '../TinyMCE';

import notification from '~/services/notification';
import api from '~/services/api';

import util from '~/assets/util';
import { Policies } from '~/assets/constants';

import styles from './index.module.scss';

interface PoliciesModalProps {
  onClose: any;
  isOpen?: any;
  load: any;
  police?: any;
}

function PoliciesModal({ onClose, isOpen = true, load, police }: PoliciesModalProps) {
  const editorRef = useRef(null);
  const [title, setTitle] = useState('');
  const [imagesIds, setImagesIds] = useState([]);
  const [hasBeenOpened, setHasBeenOpened] = useState(false);
  const [selectedType, setSelectedType] = useState(Policies.POLICE);

  useEffect(() => {
    if (!police) {
      return setTitle('');
    }

    setTitle(police.title);
    setSelectedType(police.type);
  }, [police]);

  useEffect(() => {
    if (isOpen) {
      setHasBeenOpened(true);
    }
  }, [isOpen]);

  useEffect(() => {
    const deleteImages = async () => {
      try {
        if (!isOpen && hasBeenOpened) {
          await api.post('/admin/policies/deleteImages', { status: true });
          setImagesIds([]);
          setSelectedType(Policies.POLICE);
        }
      } catch (e) {
        notification.$e(e);
      }
    };

    if (!isOpen && hasBeenOpened) {
      deleteImages();
    }
  }, [isOpen, hasBeenOpened]);

  const doSave = async (e) => {
    e.preventDefault();

    try {
      if (!editorRef?.current?.getContent().trim()) {
        notification.$e(util.t('REQUIRED_CONTENT_POLICIE'));
        return;
      }

      let url = '/admin/policies/register';
      if (police) {
        url = `/admin/policies/update/${police.police_id}`;
      }

      const form = {
        title: title,
        text: editorRef.current.getContent(),
        type: selectedType,
        images_ids: imagesIds,
      };

      await api.post(url, form);

      load();
      notification.$s(util.t('REGISTER_SUCCESSFULLY'));
    } catch (e: any) {
      const data = e.response.data;

      if (data.message) {
        notification.$e(util.t(data.message));
      } else {
        console.error('Error policie.register', e);
      }
    } finally {
      onClose();
      setImagesIds([]);
    }
  };

  const handleImageUpload = (blobInfo: any, progress: any): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', blobInfo.blob(), blobInfo.filename());

      let simulatedProgress = 0;
      const interval = setInterval(() => {
        simulatedProgress += 10;
        if (simulatedProgress >= 95) {
          clearInterval(interval);
        } else {
          progress(simulatedProgress);
        }
      }, 300);

      api
        .post('/admin/policies/saveImage', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
          clearInterval(interval);
          const { data } = response;

          if (data?.result?.police_image_id) {
            setImagesIds((prevIds) => [...prevIds, data.result.police_image_id]);
          }

          resolve(data.result.url);
          progress(100);
        })
        .catch((error) => {
          clearInterval(interval);
          reject(error);
        });
    });
  };

  return (
    <Modal
      className={styles.policieModal}
      autoFocus={false}
      isOpen={isOpen}
      size="xl"
      toggle={onClose}
      unmountOnClose>
      <Card>
        <CardHeader>{util.t('POLICIES')}</CardHeader>
        <CardBody>
          {isOpen && (
            <>
              <Form onSubmit={doSave}>
                <Row form>
                  <Col lg="6">
                    <FormGroup>
                      <Label htmlFor="title">{util.t('TITLE')}</Label>

                      <Input
                        id="title"
                        name="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        max="255"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label htmlFor="type">{util.t('TYPE')}</Label>
                      <div>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="radio"
                              name="type"
                              value="police"
                              checked={selectedType === Policies.POLICE}
                              onChange={(e) => setSelectedType(e.target.value)}
                            />
                            {util.t('POLICE')}
                          </Label>
                        </FormGroup>

                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="radio"
                              name="type"
                              value="page"
                              checked={selectedType === Policies.PAGE_CONTENT}
                              onChange={(e) => setSelectedType(e.target.value)}
                            />
                            {util.t('PAGE_CONTENT')}
                          </Label>
                        </FormGroup>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>

                <TinyMCE
                  config={{
                    height: 500,
                    menubar: false,
                    advcode_inline: true,
                    plugins:
                      'searchreplace autolink directionality visualblocks visualchars preview image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap linkchecker emoticons autosave fullscreen code',
                    toolbar:
                      'undo redo | blocks | ' +
                      'bold italic forecolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'image | removeformat | code | help',
                    automatic_uploads: true,
                    images_reuse_filename: true,
                    images_upload_handler: handleImageUpload,
                  }}
                  onInit={(_evt, editor) => {
                    editorRef.current = editor;
                    if (police) {
                      setTimeout(() => {
                        editor.setContent(police.text);
                      }, 0);
                    }
                  }}
                />
                <Col className="mt-3">
                  <Row className="justify-content-end">
                    <Button color="danger" type="button" onClick={onClose}>
                      {util.t('CANCEL')}
                    </Button>
                    <Button className="ml-2" color="primary" type="submit">
                      {police ? util.t('UPDATE') : util.t('SAVE')}
                    </Button>
                  </Row>
                </Col>
              </Form>
            </>
          )}
        </CardBody>
      </Card>
    </Modal>
  );
}

export default memo(PoliciesModal);
