import React, { memo, useEffect, useState } from 'react';

import { Button as BButton, Spinner } from 'reactstrap';

import { FaFileExcel } from 'react-icons/fa';

import Button from '../Button';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

import styles from './index.module.scss';

interface SalesReportsBoxProps {
  disabled?: boolean;
  filters: any;
}

interface DataExport {
  is_done?: boolean;
  data_export_id?: string;
  progress?: number;
  file_url: string | null;
}

function SalesReportsBox({ disabled, filters }: SalesReportsBoxProps) {
  const [loading, setLoading] = useState(false);
  const [exportData, setExportData] = useState<DataExport | null>(null);

  const requestReport = async () => {
    if (loading) return;

    try {
      setLoading(true);

      const res = await api.post('admin/orders/sales-report', filters);

      setExportData(res.data.data_export);

      notification.$s(util.t('SUCCESS'), util.t('SALES_REPORT_WILL_BE_GENERATED'), true, false);
    } catch (e) {
      notification.$e(e);
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onClickOnReport = async () => {
    if (!exportData?.is_done) return;

    window.location.href = exportData.file_url;

    const url = `admin/orders/sales-report/download/${exportData.data_export_id}`;

    try {
      await api.post(url);
    } catch (e) {
      console.error('SalesReportsBox.markAsDownloaded', e);
    }

    setExportData(null);
  };

  useEffect(() => {
    const fetchCurrentReport = async () => {
      try {
        const { data } = await api.get('admin/orders/sales-report/current');

        if (data?.data_export_id) setExportData(data);
      } catch (error) {
        console.error('SalesReportsBox.fetchCurrentReport', error);
      }
    };

    fetchCurrentReport();
  }, []);

  useEffect(() => {
    let interval = null;
    const fetchExportProgress = async (id: string) => {
      try {
        const { data } = await api.get(`admin/orders/sales-report/${id}`);

        setExportData(data);
      } catch (error) {
        console.error('SalesReportsBox.fetchExportProgress', error);
      }
    };

    if (exportData && !exportData.is_done && exportData.data_export_id) {
      interval = setInterval(() => {
        fetchExportProgress(exportData.data_export_id as string);
      }, 2500);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [exportData]);

  return (
    <>
      {exportData && (
        <BButton
          disabled={!exportData.is_done}
          className={`mb-3 mr-2 align-items-center ${styles.progressButton}`}
          color="primary"
          outline
          style={{ ['--progress' as any]: `${exportData.progress ?? 0}%` }}
          onClick={onClickOnReport}>
          <span className={styles.progressContent}>
            {exportData.is_done ? (
              util.t('REPORT_READY')
            ) : (
              <Spinner size="sm" className={styles.spinner} />
            )}
          </span>
        </BButton>
      )}

      <Button
        disabled={disabled || (exportData && !exportData.is_done)}
        loading={loading}
        onClick={requestReport}
        className="mb-3 align-items-center"
        color="primary"
        spinnerColor="primary"
        outline>
        <FaFileExcel className="mr-1" />
        {util.t('SALES_REPORT')}
      </Button>
    </>
  );
}

export default memo(SalesReportsBox);
