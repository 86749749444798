import React, { memo } from 'react';

import { Table as BTable, UncontrolledTooltip } from 'reactstrap';

import util from '~/assets/util';

import styles from './index.module.scss';

interface TableProps {
  setRef: any;
  products: any[];
  selectedIds: number[];
  toggleItemSelection: any;
  disabledBarcodes: string[];
  [key: string]: any;
}

function Table({
  setRef,
  products,
  selectedIds,
  toggleItemSelection,
  disabledBarcodes,
  ...props
}: TableProps) {
  return (
    <BTable innerRef={setRef} size="sm" className={styles.table} hover {...props}>
      <tbody>
        {products.map((product) => {
          const barcode = product.bar_code;
          const id = product.inventory_id;

          const rowId = `author-product-${id}`;
          const selected = selectedIds.indexOf(id) != -1;
          const disabled = disabledBarcodes.indexOf(barcode) != -1;

          return (
            <React.Fragment key={id}>
              <tr
                id={rowId}
                className={
                  (selected
                    ? 'table-primary'
                    : disabled
                    ? 'table-secondary text-muted disabled'
                    : '') + ` ${styles.row}`
                }
                onClick={() => !disabled && toggleItemSelection(id)}>
                <td>
                  <img src={product.thumbnail} alt={product.name} className={styles.thumbnail} />
                </td>
                <td>{product.name}</td>
              </tr>

              {disabled && (
                <UncontrolledTooltip placement="bottom" target={rowId}>
                  {util.t('THIS_PRODUCT_ALREDY_INCLUDED')}
                </UncontrolledTooltip>
              )}
            </React.Fragment>
          );
        })}
      </tbody>
    </BTable>
  );
}

export default memo(Table);
