import React, { memo, useRef, useState } from 'react';

import { Button } from 'reactstrap';

import util from '~/assets/util';
import notification from '~/services/notification';

import './index.scss';

interface ImageInputProps {
  className?: string;
  alt?: string;
  src?: string;
  id?: any;
  name?: string;
  onChange?: any;
  disabled?: any;
}

function ImageInput({
  className = '',
  alt = '',
  src = '',
  id,
  name,
  onChange,
  disabled = false,
  ...props
}: ImageInputProps) {
  const inputRef = useRef(null);

  const [dragging, setDragging] = useState(false);

  const openSelectFile = () => {
    return !disabled && inputRef.current.click();
  };

  const handleInputChange = (ev) => {
    ev.preventDefault();

    return changeFile(ev.target.files);
  };

  const changeFile = (files) => {
    if (!files.length) {
      return false;
    }

    const file = files[0];

    if (file.type.indexOf('image') == -1) {
      return notification.$e(util.t('INVALID_IMAGE'));
    }

    const reader = new FileReader();

    reader.addEventListener('load', (event) => {
      const base64 = event.target.result;

      if (base64) {
        onChange(base64, file);
      }
    });

    reader.readAsDataURL(file);
  };

  const renderBox = () => {
    if (src) {
      return <img {...{ src, alt }} />;
    }

    if (dragging) {
      return <p>{util.t('DROP_FILE')}</p>;
    }

    return (
      <>
        <span className="text-center mb-3">{util.t('DRAG_IMAGE')}</span>
        <Button type="button" color="primary" outline>
          {util.t('SELECT_FILE')}
        </Button>
      </>
    );
  };

  const handleDragEnter = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    if (ev.dataTransfer.items && ev.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragLeave = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();

    setDragging(false);
  };

  const handleDrop = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    if (disabled) {
      return;
    }

    setDragging(false);

    if (ev.dataTransfer.files && ev.dataTransfer.files.length > 0) {
      changeFile(ev.dataTransfer.files);
      ev.dataTransfer.clearData();
    }
  };

  return (
    <>
      <section
        className={`box mb-2 ${className} ${
          !disabled ? 'cursor-pointer' : 'cursor-not-allowed filter-grayscale'
        }`}
        onClick={openSelectFile}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onDragOver={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
        }}
        role="button">
        {renderBox()}
      </section>
      <input
        accept="image/*"
        ref={inputRef}
        onChange={handleInputChange}
        className="hidden-file"
        type="file"
        {...{ id, name }}
      />
    </>
  );
}

export default memo(ImageInput);
