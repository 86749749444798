import React, { useEffect, useState, useRef, useCallback } from 'react';

import Filters from './Filters';

import DiscountModal from '~/components/DiscountModal';
import EditProductPriceModal from '~/components/EditProductPriceModal';
import Pagination from '~/components/Pagination';
import ProductsTable from '~/components/ProductsTable';

import CreateSchoolListButton from './CreateSchoolListButton';

import api from '~/services/api';

import util from '~/assets/util';
import notification from '~/services/notification';

const DEFAULT_FILTERS = {
  active: '',
  name: '',
  isbn: '',
  selectedOptionAuthor: [],
  selectedOptionBrand: [],
  store_id: '',
  school_id: '',
};

function SchoolLists() {
  const [deleting, setDeleting] = useState([]);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState(null);
  const [productId, setProductId] = useState(null);

  const loadRequest = useRef(null);

  const [filters, setFilters]: any = useState(DEFAULT_FILTERS);

  const doSearch = async (page: any = 1) => {
    if (!parseInt(page)) {
      return;
    }

    // @ts-ignore
    const cancelToken = api.CancelToken;

    const source = cancelToken.source();

    try {
      setLoading(true);

      if (loadRequest.current) {
        loadRequest.current.cancel();
      } else {
        loadRequest.current = source;
      }

      const res = await api.get(`admin/products/school-lists?page=${page}`, {
        params: filters,
        cancelToken: source.token,
      });

      loadRequest.current = null;

      const { products } = res.data;

      products.links?.shift();
      products.links?.splice(products.links.length - 1, 1);

      setProducts(products);
    } catch (e) {
      console.error(e);
    } finally {
      !loadRequest.current && setLoading(false);
    }
  };

  const doDelete = useCallback(
    async (selectedIds) => {
      const ids = Array.isArray(selectedIds) ? selectedIds : [selectedIds];

      try {
        setDeleting((prev) => [...prev, ...ids]);

        const url = `admin/products/school-lists`;

        await api.delete(url, { data: { items: ids } });

        notification.$s(util.t('PRODUCT_REMOVED_FROM_LIST'));

        doSearch();
      } catch (e) {
        notification.$e(e);
      } finally {
        setDeleting((prev) => prev.filter((item) => !ids.includes(item)));
      }
    },
    [deleting, doSearch],
  );

  useEffect(() => {
    doSearch();
  }, []);

  return (
    <>
      <header className="row my-3">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <Filters
                filters={filters}
                setFilters={setFilters}
                onSave={doSearch}
                onClear={() => setFilters({ ...DEFAULT_FILTERS, clearSelect: true })}
              />
            </div>
          </div>
        </div>
      </header>

      <main className="card">
        <section className="card-body">
          <div className="container-fluid p-3">
            <header>
              <h1 className="h2">{util.t('SCHOOL_LIST')}</h1>
            </header>

            <div className="text-right">
              <CreateSchoolListButton className="my-3 mr-1" onCreate={doSearch} />
            </div>

            <ProductsTable
              deleting={deleting}
              onDelete={(ids) => doDelete(ids)}
              onOpen={(id) => {
                setProductId(id);
              }}
              loading={loading}
              products={products}
              enableSchoolListColumns
              rowKey="school_list_id"
              isMultiDelete
            />

            <div className="d-flex justify-content-end mt-4">
              {products && <Pagination onChange={(page) => doSearch(page)} {...products} />}
            </div>
          </div>
        </section>

        <DiscountModal
          isOpen={showDiscountModal}
          onToggle={() => setShowDiscountModal(false)}
          onSave={doSearch}
        />

        <EditProductPriceModal
          productId={productId}
          isOpen={productId != null}
          toggle={() => setProductId(null)}
          onClose={() => setProductId(null)}
          onSave={doSearch}
        />
      </main>
    </>
  );
}

export default SchoolLists;
