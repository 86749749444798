import React, { memo, useCallback, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';

import { Button, Spinner, Table as BTable } from 'reactstrap';

import { FaSearchPlus } from 'react-icons/fa';

import DeleteButton from '~/components/DeleteButton';

import util from '~/assets/util';

import api from '~/services/api';
import notification from '~/services/notification';

interface TableProps {
  pages: any[];
  loading?: any;
  doUpdate: any;
}

function Table({ pages, loading = false, doUpdate }: TableProps) {
  const [deleting, setDeleting] = useState([]);

  const doDelete = useCallback(
    async (id) => {
      try {
        setDeleting(deleting.concat([id]));

        const url = `admin/products-pages/${id}`;

        await api.delete(url);

        doUpdate();
      } catch (e) {
        notification.$e(e);
      } finally {
        setDeleting(deleting.filter((deletingId) => deletingId != id));
      }
    },
    [deleting, doUpdate],
  );

  const list = useMemo(
    () =>
      pages?.map((page) => (
        <tr key={page.products_page_id}>
          <td>{page.name}</td>
          <td>{page.slug}</td>
          <td>
            <div className="d-flex align-center justify-content-end">
              <Link to={`/products-pages/edit/${page.products_page_id}`}>
                <Button className="mr-1" onClick={() => {}} size="sm" outline color="info">
                  <FaSearchPlus />
                </Button>
              </Link>

              <DeleteButton
                onDelete={() => doDelete(page.products_page_id)}
                loading={deleting.indexOf(page.products_page_id) > -1}
                size="sm"
              />
            </div>
          </td>
        </tr>
      )),
    [pages, deleting, doDelete],
  );

  const renderBody = () => {
    if (loading) {
      return (
        <tr>
          <th className="text-center w-100" colSpan={3}>
            <Spinner size="sm" color="primary" />
          </th>
        </tr>
      );
    }

    if (pages?.length) {
      return list;
    }

    return (
      <tr>
        <td className="text-center" colSpan={5}>
          <span>{util.t('NO_RECORDS_FOUND')}</span>
        </td>
      </tr>
    );
  };

  return (
    <BTable hover responsive>
      <thead className="thead-light">
        <tr>
          <th>{util.t('NAME')}</th>
          <th>{util.t('SLUG')}</th>
          <th className="text-right">{util.t('ACTION')}</th>
        </tr>
      </thead>

      <tbody>{renderBody()}</tbody>
    </BTable>
  );
}

export default memo(Table);
