import React, { useState, useEffect } from 'react';
import { Button, Modal, Card, CardBody, CardHeader } from 'reactstrap';

import Table from './Table';
import Form from './Form';

import api from '~/services/api';

import util from '~/assets/util';

function Hosts() {
  const [loading, setLoading] = useState(true);
  const [hosts, setHosts] = useState([]);

  const [hostId, setHostId] = useState(null);
  const [hostDomain, setHostDomain] = useState(null);

  const doSearch = async (page: any = 1) => {
    if (!parseInt(page)) {
      return;
    }

    try {
      setLoading(true);

      const res = await api.get('admin/hosts');

      const { hosts } = res.data;

      setHosts(hosts);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const newHost = () => {
    setHostId(0);
  };

  useEffect(() => {
    doSearch();
  }, []);

  return (
    <>
      <main className="card my-3">
        <section className="card-body">
          <div className="container-fluid p-3">
            <header>
              <h1 className="h2 m-0">{util.t('DOMAINS')}</h1>
              <p>{util.t('DOMAINS_DESCRIPTION')}</p>
            </header>

            <div className="text-right">
              <Button
                disabled={hosts?.length >= 7}
                onClick={newHost}
                className="my-3"
                color="primary"
                outline>
                {util.t('ADD')}
              </Button>
            </div>

            <Table
              loading={loading}
              hosts={hosts}
              doUpdate={doSearch}
              onOpen={(id, domain) => {
                setHostId(id);
                setHostDomain(domain);
              }}
            />
          </div>
        </section>

        <Modal
          autoFocus={false}
          isOpen={hostId != null}
          toggle={() => {
            setHostId(null);
          }}
          size="md">
          <Card>
            <CardHeader>{hostId ? util.t('EDIT_DOMAIN') : util.t('NEW_DOMAIN')}</CardHeader>
            <CardBody>
              <Form
                id={hostId}
                domain={hostDomain}
                onClose={() => {
                  setHostId(null);
                  setHostDomain(null);
                }}
                onSave={() => {
                  doSearch();
                }}
              />
            </CardBody>
          </Card>
        </Modal>
      </main>
    </>
  );
}

export default Hosts;
