import React, { useState, useEffect } from 'react';

import { Button, Modal, Card, CardHeader, CardBody } from 'reactstrap';

import CouponsGeneratorButton from '../CouponsGeneratorButton';
import Filters from './Filters';
import Form from './Form';

import CouponsTable from '~/components/CouponsTable';
import Pagination from '~/components/Pagination';

import api from '~/services/api';

import util from '~/assets/util';

const DEFAULT_FILTERS = {
  active: '',
  code: '',
  expire_date: '',
};

function Coupons() {
  const [loading, setLoading] = useState(true);
  const [coupons, setCoupons] = useState(null);

  const [filters, setFilters]: any = useState(DEFAULT_FILTERS);

  const [couponId, setCouponId] = useState(null);

  const doSearch = async (page: any = 1) => {
    if (!parseInt(page)) {
      return;
    }

    try {
      setLoading(true);

      const res = await api.get(`admin/coupons?page=${page}`, {
        params: filters,
      });

      const { coupons } = res.data;

      coupons.links?.shift();
      coupons.links?.splice(coupons.links.length - 1, 1);

      setCoupons(coupons);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const newCoupon = () => {
    setCouponId(0);
  };

  useEffect(() => {
    doSearch();
  }, []);

  return (
    <>
      <header className="row my-3">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <Filters
                filters={filters}
                setFilters={setFilters}
                onSave={() => {
                  doSearch();
                }}
                onClear={() => {
                  setFilters(DEFAULT_FILTERS);
                }}
              />
            </div>
          </div>
        </div>
      </header>

      <main className="card">
        <section className="card-body">
          <div className="container-fluid p-3">
            <header>
              <h1 className="h2 m-0">{util.t('COUPONS')}</h1>
            </header>

            <div className="text-right">
              <CouponsGeneratorButton doUpdate={doSearch} />

              <Button onClick={newCoupon} className="my-3 ml-2" color="primary" outline>
                {util.t('ADD')}
              </Button>
            </div>

            <CouponsTable
              onOpen={(id) => {
                setCouponId(id);
              }}
              loading={loading}
              coupons={coupons}
              doUpdate={doSearch}
            />

            <div className="d-flex justify-content-end mt-4">
              {coupons && <Pagination onChange={(page) => doSearch(page)} {...coupons} />}
            </div>
          </div>
        </section>

        <Modal
          autoFocus={false}
          isOpen={couponId != null}
          toggle={() => {
            setCouponId(null);
          }}
          size="xl">
          <Card>
            <CardHeader>{util.t('COUPON')}</CardHeader>
            <CardBody>
              <Form
                id={couponId}
                onClose={() => {
                  setCouponId(null);
                }}
                onSave={() => {
                  doSearch();
                }}
              />
            </CardBody>
          </Card>
        </Modal>
      </main>
    </>
  );
}

export default Coupons;
