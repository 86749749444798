import React, { memo, useEffect, useState } from 'react';

import Creatable from 'react-select/creatable';

import { Button, InputGroup, InputGroupAddon, UncontrolledTooltip } from 'reactstrap';

import { FaPlus } from 'react-icons/fa';

import AttributeModal from '../AttributeModal';

import api from '~/services/api';

import util from '~/assets/util';

import { AttributesLabels } from '~/assets/constants';

interface SearchAttributeInputProps {
  onChange: any;
  onCreate?: any;
  [key: string]: any;
}

function SearchAttributeInput({ onChange, onCreate, ...props }: SearchAttributeInputProps) {
  const [options, setOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const loadAttributes = async () => {
    try {
      const res = await api.get('admin/products/attributes');

      const { attributes } = res?.data;

      const options = attributes?.map((attribute) => {
        const name = attribute.name;

        return new Object({
          name: attribute.tenant_id ? name : util.t(AttributesLabels[name]),
          tenant_id: attribute.tenant_id,
          type: attribute.type,
          attribute_id: attribute.attribute_id,
        });
      });

      setOptions(options);
    } catch (e) {
      console.error(e);
    }
  };

  const handleNewAttribute = (attribute) => {
    onCreate && onCreate(attribute);

    setShowModal(false);
  };

  useEffect(() => {
    loadAttributes();
  }, []);

  return (
    <>
      <AttributeModal
        id={0}
        isOpen={showModal}
        onSave={handleNewAttribute}
        onToggle={() => setShowModal(false)}
      />

      <InputGroup>
        <Creatable
          {...props}
          className="react-select-full-width"
          isMulti={false}
          isClearable
          placeholder={util.t('ATTRIBUTE')}
          loadingMessage={() => util.t('SEARCHING_THREE_DOTS')}
          noOptionsMessage={() => util.t('NO_OPTION')}
          getOptionLabel={(option) => option.name || option.label}
          getOptionValue={(option) => option.attribute_id}
          formatCreateLabel={(name) => `${util.t('LABEL_CREATE')} "${name}"`}
          onChange={onChange}
          options={options}
        />

        <InputGroupAddon addonType="append">
          <Button
            id="new-attribute-button"
            alt={util.t('NEW_ATTRIBUTE')}
            type="button"
            onClick={() => setShowModal(true)}
            size="sm"
            color="primary">
            <FaPlus />
          </Button>

          <UncontrolledTooltip target="new-attribute-button">
            {util.t('NEW_ATTRIBUTE')}
          </UncontrolledTooltip>
        </InputGroupAddon>
      </InputGroup>
    </>
  );
}

export default memo(SearchAttributeInput);
