import React, { memo } from 'react';

import debounce from 'lodash.debounce';

import { Row, FormGroup, Input, Label, Col, Form } from 'reactstrap';

import Button from '~/components/Button';
import StoreSelect from '~/components/StoreSelect';
import SearchBrandInput from '~/components/Brands/SearchBrandInput';
import SearchAuthorInput from '~/components/Authors/SearchAuthorInput';
import SchoolSelect from '~/components/SchoolSelect';

import api from '~/services/api';

import util from '~/assets/util';

interface FiltersProps {
  filters: any;
  setFilters: any;
  onSave: any;
  onClear: any;
}

function Filters({ filters, setFilters, onSave, onClear }: FiltersProps) {
  const doSubmit = (ev) => {
    ev.preventDefault();

    onSave();
  };

  const handleChange = (ev) => {
    const name = ev.target.name;

    setFilters({ ...filters, [name]: ev.target.value });
  };

  const handleChangeSelect = (ev, { name }) => {
    if (name == 'author_id') {
      setFilters({ ...filters, author_id: ev?.value, selectedOptionAuthor: ev });
    } else {
      setFilters({ ...filters, brand_id: ev?.value, selectedOptionBrand: ev });
    }
  };

  const handleChangeStore = (id) => {
    setFilters({ ...filters, store_id: id });
  };

  async function loadDataOptions(type, inputValue) {
    try {
      const res = await api.get(`admin/products/${type}?q=${inputValue}`);

      const { brands, authors } = res?.data;

      const dataResult = brands ?? authors;

      if (!dataResult) {
        return;
      }

      const selectOptions = dataResult?.map((item) => {
        if (type == 'brands') {
          return new Object({ name: 'brand_id', value: item.brand_id, label: item.name });
        }

        return new Object({ name: 'author_id', value: item.author_id, label: item.name });
      });

      return selectOptions?.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
    } catch (e) {
      console.error(e);
    }
  }

  const loadBrands = (inputValue) =>
    new Promise((resolve) => {
      resolve(loadDataOptions('brands', inputValue));
    });

  const loadAuthors = (inputValue) =>
    new Promise((resolve) => {
      resolve(loadDataOptions('authors', inputValue));
    });

  const debouncedLoadAuthors = debounce((inputValue, callback) => {
    inputValue.length > 2 && loadAuthors(inputValue).then((options) => callback(options));
  }, 1000);

  const debouncedLoadBrands = debounce((inputValue, callback) => {
    inputValue.length > 2 && loadBrands(inputValue).then((options) => callback(options));
  }, 1000);

  return (
    <Form onSubmit={doSubmit}>
      <Row form>
        <FormGroup className="col-md-2">
          <Label htmlFor="active">{util.t('STATUS')}</Label>
          <select
            className="form-control"
            value={filters.active}
            id="active"
            name="active"
            onChange={handleChange}>
            <option value="">{util.t('ALL')}</option>
            <option value="1">{util.t('ACTIVE')}</option>
            <option value="0">{util.t('INACTIVE')}</option>
          </select>
        </FormGroup>

        <FormGroup className="col-md-2">
          <Label htmlFor="filter_name">{util.t('TITLE')}</Label>
          <Input
            id="filter_name"
            name="name"
            type="text"
            onChange={handleChange}
            value={filters.name}
          />
        </FormGroup>

        <FormGroup className="col-md-2">
          <Label htmlFor="filter_isbn">{util.t('ISBN')}</Label>
          <Input
            id="filter_isbn"
            name="isbn"
            type="text"
            onChange={handleChange}
            value={filters.isbn}
          />
        </FormGroup>

        <FormGroup className="col-md-3">
          <Label htmlFor="filter_authors">{util.t('AUTHORS')}</Label>

          <SearchAuthorInput
            name="author_id"
            isMulti={false}
            value={filters.selectedOptionAuthor}
            onChange={handleChangeSelect}
            loadOptions={debouncedLoadAuthors}
            canCreate={false}
          />
        </FormGroup>

        <FormGroup className="col-md-3">
          <Label htmlFor="filter_brands">{util.t('BRANDS')}</Label>

          <SearchBrandInput
            name="brand_id"
            value={filters.selectedOptionBrand}
            onChange={handleChangeSelect}
            loadOptions={debouncedLoadBrands}
            canCreate={false}
          />
        </FormGroup>

        <FormGroup className="col-md-3">
          <Label htmlFor="school"> {util.t('SCHOOL')} </Label>
          <SchoolSelect
            value={filters.school}
            allowCreate={false}
            placeholder={util.t('SELECT')}
            onChange={(school) =>
              setFilters((prev) => ({
                ...prev,
                school_id: school?.school_id ?? null,
              }))
            }
          />
        </FormGroup>

        <FormGroup className="col-md-3">
          <Label htmlFor="store_id"> {util.t('DISTRIBUTION_CENTER')} </Label>
          <StoreSelect
            showOptionAll
            withPublicStores
            className="mb-3"
            id="store_id"
            value={filters.store_id}
            name="store_id"
            onChange={handleChangeStore}
          />
        </FormGroup>
      </Row>

      <Row form>
        <Col>
          <Button type="submit" color="primary">
            {util.t('SEARCH')}
          </Button>
          <Button className="ml-1" onClick={onClear} type="button" color="secondary">
            {util.t('CLEAR')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default memo(Filters);
