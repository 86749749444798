import React, { memo, useCallback, useMemo, useState } from 'react';

import { Button, Spinner, Table } from 'reactstrap';

import { FaSearchPlus } from 'react-icons/fa';

import DeleteButton from '../DeleteButton';

import util from '~/assets/util';

import api from '~/services/api';
import notification from '~/services/notification';

import './index.scss';

interface CategoriesTableProps {
  categories: any[];
  loading?: any;
  doUpdate: any;
  onOpen: any;
}

function CategoriesTable({ categories, loading = false, doUpdate, onOpen }: CategoriesTableProps) {
  const [deleting, setDeleting] = useState([]);

  const doDelete = useCallback(
    async (id) => {
      try {
        setDeleting(deleting.concat([id]));

        const url = `admin/menu-categories/${id}`;

        await api.delete(url);

        doUpdate();
      } catch (e) {
        notification.$e(e);
      } finally {
        setDeleting(deleting.filter((deletingId) => deletingId != id));
      }
    },
    [deleting, doUpdate],
  );

  const list = useMemo(
    () =>
      categories?.map((category) => (
        <tr key={category.tenant_category_id}>
          <td>{category.position}</td>
          <td>{category.label || category.name}</td>
          <td>
            <div className="d-flex align-center justify-content-end">
              <Button
                className="mr-1"
                onClick={() => onOpen(category.tenant_category_id)}
                size="sm"
                outline
                color="info">
                <FaSearchPlus />
              </Button>

              <DeleteButton
                onDelete={() => doDelete(category.tenant_category_id)}
                loading={deleting.indexOf(category.tenant_category_id) > -1}
                size="sm"
              />
            </div>
          </td>
        </tr>
      )),
    [categories, deleting, doDelete, onOpen],
  );

  const renderBody = () => {
    if (loading) {
      return (
        <tr>
          <th className="text-center w-100" colSpan={6}>
            <Spinner size="sm" color="primary" />
          </th>
        </tr>
      );
    }

    if (categories?.length) {
      return list;
    }

    return (
      <tr>
        <td className="text-center" colSpan={5}>
          <span>{util.t('NO_RECORDS_FOUND')}</span>
        </td>
      </tr>
    );
  };

  return (
    <>
      <Table hover responsive>
        <thead className="thead-light">
          <tr>
            <th>{util.t('POSITION')}</th>
            <th>{util.t('NAME')}</th>
            <th className="text-right">{util.t('ACTION')}</th>
          </tr>
        </thead>

        <tbody>{renderBody()}</tbody>
      </Table>
    </>
  );
}

export default memo(CategoriesTable);
