import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Button } from 'reactstrap';

import Table from './Table';

import api from '~/services/api';

import util from '~/assets/util';

function ProductsPages() {
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState([]);

  const doSearch = async () => {
    try {
      setLoading(true);

      const res = await api.get('admin/products-pages');

      setPages(res.data.pages);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    doSearch();
  }, []);

  return (
    <main className="card my-3">
      <section className="card-body">
        <div className="container-fluid p-3">
          <header>
            <h1 className="h2 m-0">{util.t('PRODUCTS_PAGES')}</h1>
            <p>{util.t('PRODUCTS_PAGES_DESCRIPTION')}</p>
          </header>

          <div className="text-right">
            <Link to="/products-pages/new">
              <Button className="my-3" color="primary" outline>
                {util.t('ADD')}
              </Button>
            </Link>
          </div>

          <Table loading={loading} pages={pages} doUpdate={doSearch} />
        </div>
      </section>
    </main>
  );
}

export default ProductsPages;
