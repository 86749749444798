import React from 'react';

import { Modal, Card, CardBody, CardHeader } from 'reactstrap';

import Form from './Form';

import util from '~/assets/util';

import { CatalogueType } from '~/types/catalogue';

interface CatalogueModalProps {
  id: number | string | null;
  onToggle: () => void;
  onClose: () => void;
  onSave: (data) => void;
  catalogueType?: CatalogueType;
  enablePositionField?: boolean;
  enableStatusField?: boolean;
  defaultPosition?: any;
}

function CatalogueModal({
  id,
  onToggle,
  onClose,
  onSave,
  catalogueType = CatalogueType.HOME_PAGE,
  enablePositionField = true,
  enableStatusField = true,
  defaultPosition = 1,
}: CatalogueModalProps) {
  return (
    <Modal autoFocus={false} isOpen={id != null} toggle={onToggle} size="xl">
      <Card>
        <CardHeader>{id ? util.t('EDIT_CATALOGUE') : util.t('NEW_CATALOGUE')}</CardHeader>
        <CardBody>
          <Form
            id={id}
            onClose={onClose}
            onSave={onSave}
            defaultPosition={defaultPosition}
            catalogueType={catalogueType}
            enablePositionField={enablePositionField}
            enableStatusField={enableStatusField}
          />
        </CardBody>
      </Card>
    </Modal>
  );
}

export default CatalogueModal;
