import React from 'react';

import { Card, CardBody, CardHeader, Modal } from 'reactstrap';

import Form from './Form';

import util from '~/assets/util';

import { ImageSectionType } from '~/types/image-section';

interface ImageSectionModalProps {
  id: number | string | null;
  onToggle: () => void;
  onClose: () => void;
  onSave: (data) => void;
  enablePositionField?: boolean;
  enableStatusField?: boolean;
  imageSectionType?: ImageSectionType;
}

function ImageSectionModal({
  id,
  onToggle,
  onClose,
  onSave,
  enablePositionField = true,
  enableStatusField = true,
  imageSectionType = ImageSectionType.HOME_PAGE,
}: ImageSectionModalProps) {
  return (
    <Modal autoFocus={false} isOpen={id != null} toggle={onToggle} size="xl">
      <Card>
        <CardHeader>{util.t('IMAGE_SECTION')}</CardHeader>
        <CardBody>
          <Form
            id={id}
            onClose={onClose}
            onSave={onSave}
            enablePositionField={enablePositionField}
            enableStatusField={enableStatusField}
            imageSectionType={imageSectionType}
          />
        </CardBody>
      </Card>
    </Modal>
  );
}

export default ImageSectionModal;
