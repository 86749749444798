import React, { memo, useRef, useState } from 'react';

import { FaTrash } from 'react-icons/fa';

import { FormGroup, Row, Col, Label, Input, Table, Progress } from 'reactstrap';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import AppendProductImageButton from '~/components/AppendProductImageButton';
import Button from '~/components/Button';
import FormButtons from '~/components/FormButtons';
import ImportProductsButton from '~/components/ImportProductsButton';
import ProductAvailabilityBadge from '~/components/ProductAvailabilityBadge';
import SearchProductNameInput from '~/components/SearchProductNameInput';
import SelectAuthorProducts from '~/components/SelectAuthorProducts';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

import './index.scss';

const ReactSwal = withReactContent(Swal);

interface FinalStepFormProps {
  products: any;
  highlightPendingImages: boolean;
  handleChangeSelect: any;
  setProducts: any;
  selectedProduct: any;
  setLoading: any;
  setSelectedProduct: any;
  appendImageButtonsRefs: any;
  onClose: any;
  formRef: any;
  saving: boolean;
}

function FinalStepForm({
  products,
  selectedProduct,
  highlightPendingImages,
  handleChangeSelect,
  setProducts,
  setLoading,
  setSelectedProduct,
  appendImageButtonsRefs,
  onClose,
  saving,
  formRef,
}: FinalStepFormProps) {
  const [loadingSpreadsheet, setLoadingSpreadsheet] = useState(false);
  const [barcodeQuery, setBarcodeQuery] = useState('');
  const [searchingBarcode, setSearchingBarcode] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState('title');
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [spreadsheetProcessedProducts, setSpreadsheetProcessedProducts] = useState(0);
  const [spreadsheetTotalProducts, setSpreadsheetTotalProducts] = useState(0);

  const importProgressPercentage = (spreadsheetProcessedProducts / spreadsheetTotalProducts) * 100;

  const productNameInputRef = useRef(null);
  const productIsbnInputRef = useRef(null);

  const selectedBarcodes = products.map((product) => product.barcode);

  const parseApiProduct = (data) =>
    new Object({
      id: data.product_id,
      active: data.active,
      barcode: data.bar_code,
      companyName: data.company_name,
      hasStock: data.has_stock,
      name: data.name,
      outOfCatalogue: data.out_of_catalogue,
      pendingImage: !data.has_thumbnail,
      stock: data.stock,
      thumbnail: data.thumbnail,
    });

  const importSpreadsheet = async (file) => {
    const notFoundBarcodes = [];
    const importPromises = [];

    setSpreadsheetTotalProducts(file.length);
    setLoadingSpreadsheet(true);
    setShowProgressBar(true);

    for (let i = 0; i < file.length; i++) {
      const row = file[i];
      const barcode = row['BAR_CODE'];
      if (!barcode) {
        notification.$e(util.t('FIRST_ROW_SHOULD_BE_BAR_CODE'));
        break;
      }

      const duplicated = products.some((product) => product.barcode == barcode);
      if (duplicated) {
        setSpreadsheetProcessedProducts((prev) => prev + 1);
        continue;
      }

      importPromises.push(
        importProduct(barcode, 'barcode').then((found) => {
          setSpreadsheetProcessedProducts((prev) => prev + 1);

          if (found || notFoundBarcodes.includes(barcode)) return;

          notFoundBarcodes.push(barcode);
        }),
      );
    }

    Promise.all(importPromises).then(() => {
      setLoadingSpreadsheet(false);

      window.setTimeout(() => {
        setShowProgressBar(false);

        if (notFoundBarcodes.length) {
          return reportNotFoundBarcodes(notFoundBarcodes);
        }

        notification.$s(util.t('PRODUCTS_IMPORTED_SUCCESSFULLY'));
      }, 500);

      window.setTimeout(() => {
        setSpreadsheetProcessedProducts(0);
      }, 1000);
    });
  };

  const reportNotFoundBarcodes = (codes) => {
    ReactSwal.fire({
      icon: 'warning',
      html: (
        <>
          <p>{util.t('UNABLE_TO_FIND_FOLLOWING_BARCODES')}</p>

          <div className="alert alert-warning">
            <ul className="p-0 m-0 text-left list-unstyled ">
              {codes.map((code, index) => (
                <li className="py-1" key={index}>
                  {code}
                </li>
              ))}
            </ul>
          </div>
        </>
      ),
      confirmButtonText: util.t('OK'),
      showConfirmButton: false,
    });
  };

  const importProduct = async (query, criteria = 'id') => {
    const url =
      criteria == 'barcode'
        ? `/admin/products/searchBarcode?q=${query}`
        : `/admin/products/inventory/${query}`;

    try {
      const res = await api.get(url);

      const { inventory } = res?.data;

      if (!inventory) {
        return false;
      }

      const product = parseApiProduct(inventory);

      setProducts((products) => [...products, product]);

      return true;
    } catch (e) {
      console.error(e);
    }
  };

  const importBarcode = async () => {
    const duplicated = validateDuplicatedBarcode(barcodeQuery);
    if (duplicated) return false;

    setSearchingBarcode(true);

    const found = await importProduct(barcodeQuery, 'barcode');

    setSearchingBarcode(false);
    setBarcodeQuery('');

    if (!found) {
      return notification.$e(util.t('PRODUCT_NOT_FOUND'));
    }

    scrollToBottom();
  };

  const clearSearch = async () => {
    await productNameInputRef.current?.select.select.clearValue();
  };

  const validateDuplicatedBarcode = (code) => {
    const duplicated = products.some((product) => product.barcode == code);
    if (duplicated) {
      notification.notify(
        util.t('THIS_PRODUCT_IS_ALREADY_ON_LIST'),
        '',
        'warning',
        true,
        'bottom-end',
      );
      return true;
    }

    return false;
  };

  const importSelectedProduct = async () => {
    if (!selectedProduct || !selectedProduct.barcode) {
      return false;
    }

    const duplicated = validateDuplicatedBarcode(selectedProduct.barcode);
    if (duplicated) {
      return;
    }

    await clearSearch();

    setProducts((products) => [...products, selectedProduct]);
    setSelectedProduct(null);

    scrollToBottom();
  };

  const doDelete = (id) => {
    setProducts(products.filter((product) => product.id !== id));
  };

  const addProductThumbnail = (id, url) => {
    const idx = products.findIndex((product) => product.id == id);
    const aux = [...products];

    aux[idx].pendingImage = false;
    aux[idx].thumbnail = `${url}?${new Date().getTime()}`;

    setProducts(aux);
  };

  const importInventories = (ids) => {
    setLoading(true);

    const promises = [];

    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];

      promises.push(importProduct(id).then((res) => res));
    }

    Promise.all(promises).then(() => {
      setLoading(false);

      window.setTimeout(scrollToBottom, 500);
    });
  };

  const scrollToBottom = () => {
    formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  };

  return (
    <>
      <fieldset>
        <Row form>
          <Col>
            <Label htmlFor="name" className="d-block h5">
              {util.t('ADD_BOOK')}
            </Label>
          </Col>
        </Row>
        <Row form className="mb-3">
          <Col xl="8">
            <div id="search-container">
              {searchCriteria == 'title' && (
                <div className="search-product-form-group">
                  <SearchProductNameInput
                    className="search-product-form-field"
                    value={selectedProduct}
                    setRef={(ref) => (productNameInputRef.current = ref)}
                    onChange={handleChangeSelect}
                  />

                  <Button
                    id="btn-add-product"
                    disabled={!selectedProduct}
                    color="primary"
                    type="button"
                    onClick={importSelectedProduct}>
                    {util.t('ADD')}
                  </Button>
                </div>
              )}

              {searchCriteria == 'isbn' && (
                <div id="search-barcode-form-group" className="search-product-form-group">
                  <input
                    type="text"
                    className="form-control search-product-form-field"
                    placeholder={util.t('TYPE_THE_ISBN')}
                    value={barcodeQuery}
                    onChange={(ev) => setBarcodeQuery(ev.target.value)}
                    ref={productIsbnInputRef}
                    required
                  />

                  <Button
                    color="primary"
                    type="button"
                    onClick={importBarcode}
                    loading={searchingBarcode}
                    disabled={!barcodeQuery.length}>
                    {util.t('ADD')}
                  </Button>
                </div>
              )}

              {searchCriteria == 'author' && (
                <SelectAuthorProducts
                  disabledBarcodes={selectedBarcodes}
                  onConfirm={(selectedIds) => importInventories(selectedIds)}
                  maxProducts={null}
                />
              )}
            </div>

            <FormGroup className="mt-3">
              <Label htmlFor="search-criteria" className="d-block">
                {util.t('SEARCH_BY')}
              </Label>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    name="search-criteria"
                    value="title"
                    checked={searchCriteria == 'title'}
                    onChange={() => setSearchCriteria('title')}
                  />
                  {util.t('TITLE')}
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    name="search-criteria"
                    value="isbn"
                    checked={searchCriteria == 'isbn'}
                    onChange={() => setSearchCriteria('isbn')}
                  />
                  {util.t('ISBN')}
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    name="search-criteria"
                    value="author"
                    checked={searchCriteria == 'author'}
                    onChange={() => setSearchCriteria('author')}
                  />
                  {util.t('AUTHOR')}
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="3" xl="3">
            <Label htmlFor="products-spreadsheet">{util.t('IMPORT_FILE')}</Label>
            <ImportProductsButton
              id="products-spreadsheet"
              disabled={loadingSpreadsheet}
              name="products-spreadsheet"
              onChange={importSpreadsheet}
              title={util.t('SELECT_FILE')}
              alt={util.t('SELECT_FILE')}
            />
          </Col>

          <hr />
        </Row>
        <Row>
          <Col
            className={`products-spreadsheet-progress-bar ${showProgressBar ? '' : 'completed'}`}>
            <Progress animated color="primary" value={importProgressPercentage}>
              {util.t('LOADING')}
            </Progress>
          </Col>
        </Row>
      </fieldset>
      <hr />
      <Row>
        <Col>
          {products.length > 0 && (
            <Table responsive hover>
              <thead className="thead-light">
                <tr>
                  <th>{util.t('IMAGE')}</th>
                  <th>{util.t('PRODUCT')}</th>
                  <th>{util.t('DISTRIBUTION_CENTER')}</th>
                  <th>{util.t('STOCK')}</th>
                  <th>{util.t('ACTION')}</th>
                </tr>
              </thead>
              <tbody>
                {products?.map(
                  ({
                    id,
                    name,
                    stock,
                    pendingImage,
                    active,
                    hasStock,
                    thumbnail,
                    companyName,
                    outOfCatalogue,
                  }) => {
                    const highlightClass =
                      pendingImage && highlightPendingImages
                        ? 'table-warning'
                        : !active
                        ? 'disabled-product-row text-muted table-danger'
                        : !hasStock
                        ? 'disabled-product-row text-muted'
                        : '';

                    return (
                      <tr key={id} className={highlightClass}>
                        <td>
                          <img
                            alt={name}
                            className="img-fluid"
                            src={thumbnail}
                            width="80"
                            height="80"
                          />
                        </td>
                        <td>
                          <span>{name}</span>
                          <ProductAvailabilityBadge
                            {...{ active, hasStock, outOfCatalogue }}
                            className="product-row-badge"
                          />
                        </td>
                        <td>{companyName}</td>
                        <td>{stock}</td>
                        <td>
                          <div className="d-flex">
                            {pendingImage && (
                              <AppendProductImageButton
                                innerRef={(el) => (appendImageButtonsRefs.current[id] = el)}
                                productId={id}
                                className={`mr-1 ${highlightPendingImages ? 'pulsate-btn' : ''}`}
                                onChange={(id, url) => addProductThumbnail(id, url)}
                                currentUrl={thumbnail}
                              />
                            )}

                            <Button
                              outline
                              color="danger"
                              type="button"
                              size="sm"
                              onClick={() => doDelete(id)}>
                              <FaTrash />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  },
                )}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <footer className="d-flex justify-content-end">
            <FormButtons
              isEditing={false}
              isSaving={saving}
              onCancel={() => onClose()}
              onEdit={() => {}}
            />
          </footer>
        </Col>
      </Row>
    </>
  );
}

export default memo(FinalStepForm);
