import React, { memo } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaBars, FaSignOutAlt, FaBook } from 'react-icons/fa';

import userService from '~/services/user';

import './index.scss';

interface NavbarProps {
  setCollapsed: any;
  collapsed: any;
}

function Navbar({ setCollapsed, collapsed }: NavbarProps) {
  const tenant = useSelector((state: any) => state.tenant);

  const navigate = useNavigate();

  const doLogout = () => {
    userService.logout();

    navigate('/login');
  };

  return (
    <>
      <div className="top-nav">
        <ul className="list-unstyled float-right mb-0 menu">
          <li className="notification-list"></li>
          <li className="notification-list">
            <button type="button" className={`btn btn-link text-black nav-link`} onClick={doLogout}>
              <FaSignOutAlt color="#fff" />
            </button>
          </li>
        </ul>

        <div className="logo-box">
          <Link className="logo" to="/">
            <span className="logo-large">
              {tenant?.logo_url !== '' ? (
                <img src={tenant?.logo_url} alt={tenant?.name} />
              ) : (
                <FaBook color="#fff" size={22} />
              )}
            </span>
            <span className="logo-small">
              {tenant?.logo_url !== '' ? (
                <img src={tenant?.logo_url} alt={tenant?.name} />
              ) : (
                <FaBook color="#fff" size={22} />
              )}
            </span>
          </Link>
        </div>

        <ul className="menu m-0">
          <li>
            <button className="button-menu-mobile" onClick={() => setCollapsed(!collapsed)}>
              <FaBars color="#fff" />
            </button>
          </li>
        </ul>
      </div>
    </>
  );
}

export default memo(Navbar);
