import React, { memo } from 'react';

import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

interface PaginationComponentProps {
  onChange: any;
  current_page: any;
  links: any[];
  last_page: any;
}

function PaginationComponent({
  onChange,
  current_page,
  links,
  last_page,
}: PaginationComponentProps) {
  return (
    <Pagination>
      <PaginationItem
        disabled={current_page === 1}
        onClick={(ev) => current_page != 1 && onChange(1)}>
        <PaginationLink first />
      </PaginationItem>

      {links.map((link, index) => (
        <PaginationItem
          key={index}
          active={link.active}
          disabled={!link.url}
          onClick={() => current_page != link.label && onChange(link.label)}>
          <PaginationLink>{link.label}</PaginationLink>
        </PaginationItem>
      ))}

      <PaginationItem
        disabled={current_page === last_page}
        onClick={() => current_page != last_page && onChange(last_page)}>
        <PaginationLink last />
      </PaginationItem>
    </Pagination>
  );
}

export default memo(PaginationComponent);
