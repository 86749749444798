import React, { useState, memo } from 'react';

import { Button } from 'reactstrap';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import CatalogueModal from '~/components/CatalogueModal';
import ImageSectionModal from '~/components/ImageSectionModal';

import BlockItem from './BlockItem';

import util from '~/assets/util';

import { ImageSectionType } from '~/types/image-section';
import { CatalogueType } from '~/types/catalogue';

import styles from './index.module.scss';

export type BlockType = 'catalogue' | 'image-section';

export interface PageBlock {
  key: string;
  id: string;
  type: BlockType;
  title?: string;
  imageUrl?: string;
  firstImage?: string;
  secondImage?: string;
  imageSectionId?: number;
  catalogueId?: number;
  items?: any[];
}

interface Props {
  blocks: PageBlock[];
  onChangeBlocks: (updated: PageBlock[]) => void;
}

function BlocksManager({ blocks, onChangeBlocks }: Props) {
  const [editingImageSectionId, setEditingImageSectionId] = useState<number | null>(null);
  const [editingCatalogueId, setEditingCatalogueId] = useState<number | null>(null);

  const getBlockKey = () => `block-${Date.now()}`;

  function handleDragEnd(result: any) {
    if (!result.destination) return;
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    if (sourceIndex === destinationIndex) return;
    const updated = Array.from(blocks);
    const [removed] = updated.splice(sourceIndex, 1);
    updated.splice(destinationIndex, 0, removed);
    onChangeBlocks(updated);
  }

  function addCatalogueBlock() {
    setEditingCatalogueId(0);
  }

  function addImageSectionBlock() {
    setEditingImageSectionId(0);
  }

  function handleEditImageSection(blockId: string) {
    const block = blocks.find((b) => b.key === blockId);
    if (block?.imageSectionId) {
      setEditingImageSectionId(block.imageSectionId);
    }
  }

  function handleEditCatalogue(blockId: string) {
    const block = blocks.find((b) => b.key === blockId);
    if (block?.catalogueId) {
      setEditingCatalogueId(block.catalogueId);
    }
  }

  function handleImageSectionSave(data: any) {
    const imageSection = data.image_section;
    if (editingImageSectionId === 0) {
      const newBlock: PageBlock = {
        id: null,
        key: getBlockKey(),
        type: 'image-section',
        imageSectionId: imageSection.image_section_id,
        title: imageSection.title,
        firstImage: imageSection.first_image,
        secondImage: imageSection.second_image,
      };
      onChangeBlocks([...blocks, newBlock]);
      setEditingImageSectionId(null);
      return;
    }

    const updated = blocks.map((block) => {
      if (block.imageSectionId != editingImageSectionId) {
        return block;
      }

      return {
        ...block,
        title: imageSection.title,
        firstImage: imageSection.first_image,
        secondImage: imageSection.second_image,
      };
    });

    onChangeBlocks(updated);
    setEditingImageSectionId(null);
  }

  function handleCatalogueSave(catalogueData: any) {
    const { catalogue } = catalogueData;
    if (!catalogue) {
      setEditingCatalogueId(null);
      return;
    }

    if (editingCatalogueId === 0) {
      const newBlock: PageBlock = {
        id: null,
        key: getBlockKey(),
        type: 'catalogue',
        catalogueId: catalogue.catalogue_id,
        title: catalogue.name,
        items: catalogue.items || [],
      };

      onChangeBlocks([...blocks, newBlock]);

      setEditingCatalogueId(null);

      return;
    }

    const updated = blocks.map((block) => {
      if (block.catalogueId === editingCatalogueId) {
        return {
          ...block,
          title: catalogue.name,
          items: catalogue.items || [],
        };
      }
      return block;
    });

    onChangeBlocks(updated);
    setEditingCatalogueId(null);
  }

  function removeBlock(key: string) {
    onChangeBlocks(blocks.filter((b) => b.key !== key));
  }

  return (
    <div>
      <h4>{util.t('PAGE_BLOCKS')}</h4>

      <div className="my-3">
        <Button color="primary" className="mr-2" onClick={addCatalogueBlock}>
          + {util.t('ADD_CATALOGUE')}
        </Button>
        <Button color="secondary" onClick={addImageSectionBlock}>
          + {util.t('ADD_IMAGE_SECTION')}
        </Button>
      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable-blocks">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {blocks.map((block, index) => (
                <Draggable key={block.key} draggableId={block.key} index={index}>
                  {(providedDrag) => (
                    <div
                      ref={providedDrag.innerRef}
                      {...providedDrag.draggableProps}
                      {...providedDrag.dragHandleProps}
                      className={`border p-3 mb-3 ${styles.block}`}
                      style={providedDrag.draggableProps.style}>
                      <BlockItem
                        block={block}
                        onRemove={() => removeBlock(block.key)}
                        onEditImageSection={() => handleEditImageSection(block.key)}
                        onEditCatalogue={() => handleEditCatalogue(block.key)}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <ImageSectionModal
        id={editingImageSectionId}
        onToggle={() => setEditingImageSectionId(null)}
        onClose={() => setEditingImageSectionId(null)}
        onSave={handleImageSectionSave}
        enablePositionField={false}
        enableStatusField={false}
        imageSectionType={ImageSectionType.PRODUCT_PAGE}
      />

      <CatalogueModal
        id={editingCatalogueId}
        onToggle={() => setEditingCatalogueId(null)}
        onClose={() => setEditingCatalogueId(null)}
        onSave={handleCatalogueSave}
        catalogueType={CatalogueType.PRODUCTS_PAGE}
        enablePositionField={false}
        enableStatusField={false}
      />
    </div>
  );
}

export default memo(BlocksManager);
