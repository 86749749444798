import React, { useState } from 'react';

import { Button } from 'reactstrap';

import Modal from './Modal';

import util from '~/assets/util';

interface CreateSchoolListButtonProps {
  onCreate: any;
  [key: string]: any;
}

function CreateSchoolListButton({ onCreate, ...props }: CreateSchoolListButtonProps) {
  const [showModal, setShowModal] = useState(false);

  return (
    <div {...props}>
      <Button {...props} color="primary" outline type="button" onClick={() => setShowModal(true)}>
        {util.t('ADD')}
      </Button>

      <Modal
        isOpen={showModal}
        unmountOnClose
        toggle={() => setShowModal(false)}
        onToggle={onCreate}
        onSave={onCreate}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
}

export default CreateSchoolListButton;
