import React, { memo, useState } from 'react';

import { Button } from 'reactstrap';

import Modal from './Modal';

import util from '~/assets/util';

function ImportRulesButton(props) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button {...props} color="primary" outline type="button" onClick={() => setShowModal(true)}>
        {util.t('IMPORT_PRICE_RULES')}
      </Button>

      <Modal
        autoFocus={false}
        isOpen={showModal}
        unmountOnClose
        toggle={() => setShowModal(false)}
      />
    </>
  );
}

export default memo(ImportRulesButton);
