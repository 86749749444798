export const Roles = {
  USER: 'user',
  ADMIN: 'admin',
};

export const PersonTypes = {
  NATURAL_PERSON: 'natural',
  JURIDICAL_PERSON: 'juridical',
};

export const PaymentMethods = {
  PAYMENT_METHOD_PIX: 'pix',
  PAYMENT_METHOD_BOLETO: 'boleto',
  PAYMENT_METHOD_ONLINE_DEBIT: 'eft',
  PAYMENT_METHOD_CREDIT_CARD: 'creditCard',
  PAYMENT_METHOD_CREDIT_CARD_MACHINE: 'creditCardMachine',
  PAYMENT_METHOD_VOUCHER: 'voucher',
  PAYMENT_METHOD_CASH: 'cash',
};

export const OrderStatus = {
  STATUS_PENDING_PAYMENT: 0,
  STATUS_CONFIRMED_PAYMENT: 1,
  STATUS_PROCESSING: 2,
  STATUS_SHIPPED: 3,
  STATUS_READY_FOR_PICKUP: 8,
  STATUS_COMPLETED: 4,
  STATUS_CANCELED: 5,
  STATUS_REFUNDED: 6,
  STATUS_IN_DISPUTE: 7,
};

export const CouponType = {
  FREE_SHIPPING: 'shipping',
  PERCENT_DISCOUNT: 'percent',
  CASH: 'cash',
};

export const CouponCodeTypes = {
  ALPHANUMERIC: 'alphanumeric',
  NUMERIC: 'numeric',
};

export const Chars = {
  ALPHANUMERIC: '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
  NUMERIC: '0123456789',
};

export const MenuType = {
  LINK: 'link',
  CATEGORY: 'category',
};

export const ListStates = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
};

export const AttributesLabels = {
  bar_code: 'BARCODE',
  type: 'BOOKBINDING',
  pages: 'ATTRIBUTE_PAGES',
  format: 'ATTRIBUTE_FORMAT',
  active: 'ATTRIBUTE_ACTIVE',
  spine: 'ATTRIBUTE_SPINE',
  width: 'ATTRIBUTE_WIDTH',
  height: 'ATTRIBUTE_HEIGHT',
  length: 'ATTRIBUTE_LENGTH',
  publish_date: 'PUBLISH_DATE',
  color: 'COLOR',
  general_type: 'TYPE',
  edition: 'EDITION_NUMBER',
  subtitle: 'SUBTITLE',
  bisac: 'BISAC',
  weight: 'WEIGHT',
  thema: 'THEMA',
  language: 'LANGUAGE',
};

export const TenantsSlug = {
  FEIRA_LER: 'feira-ler',
};

export const PaymentPlatforms = {
  PAGSEGURO: 'pagseguro',
  REDE: 'rede',
};

export const PagSeguro = {
  V2: 'v2',
  V4: 'v4',
};

export const Catalogues = {
  LISTING_TYPE_SLIDE: 1,
  LISTING_TYPE_LIST: 2,
};

export const Policies = {
  POLICE: 'police',
  PAGE_CONTENT: 'page',
  POLICIES: 'policies',
};

export const ProductTypes = {
  BOOK: 'book',
  OTHER: 'other',
};
