import React from 'react';

import { useParams } from 'react-router-dom';

import Form from './Form';

import util from '~/assets/util';

function ProductsPage() {
  const { id } = useParams();

  return (
    <main className="card my-3">
      <section className="card-body">
        <div className="container-fluid p-3">
          <header>
            <h1 className="h2 m-0">{util.t(id ? 'EDIT_PRODUCTS_PAGE' : 'NEW_PRODUCTS_PAGE')}</h1>
          </header>

          <Form id={id} />
        </div>
      </section>
    </main>
  );
}

export default ProductsPage;
