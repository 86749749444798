import React, { memo, useEffect, useRef, useState } from 'react';

import { FormGroup, Row, Col, Label, Input, Spinner } from 'reactstrap';

import NumberFormat from 'react-number-format';

import FormButtons from '~/components/FormButtons';
import ImageInput from '~/components/ImageInput';

import api from '~/services/api';
import notification from '~/services/notification';
import productService from '~/services/product';

import util from '~/assets/util';

interface FormProps {
  id: any;
  onClose?: any;
  onSave?: any;
}

function Form({ id, onClose, onSave }: FormProps) {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);
  const [product, setProduct] = useState(null);
  const [isModified, setIsModified] = useState(false);

  const inputPriceRef = useRef(null);
  const coverFileRef = useRef(null);

  const handleChange = (ev) => {
    const name = ev.target.name;

    setProduct({ ...product, [name]: ev.target.value });

    if (name == 'product_price' || name == 'discount_percent') {
      setIsModified(true);
    }
  };

  const handleChangePriceDiscount = (ev) => {
    if (ev.target.value <= 100) {
      const value_discount = (product.price * ev.target.value) / 100;
      const price = util.format(product.price - value_discount);

      setProduct({ ...product, percent: ev.target.value, product_price: price });
      setIsModified(true);
    } else {
      setProduct({ ...product, percent: 0, product_price: 0 });
    }
  };

  const doSave = async (ev) => {
    ev.preventDefault();

    if (saving) {
      return false;
    }

    setSaving(true);

    try {
      const form = new FormData();

      form.append('product_id', id);
      form.append('price', product.price);
      form.append('product_price', product.product_price);
      form.append('inventory_id', product.inventory_id);
      form.append('thumbnail', product.thumbnail);
      form.append('rule_expires_at', product.rule_expires_at);

      const coverFile = coverFileRef?.current;
      if (coverFile) {
        form.append('cover', coverFile);
      }

      const url = 'admin/products/price/update';

      const res = await api.post(url, form, { headers: { 'Content-Type': 'multipart/form-data' } });

      const data = res?.data;

      if (data) {
        onSave();
        onClose();

        notification.$s(util.t('PRODUCT_SAVED'));
      }
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  const cancelEdit = () => {
    if (id === 0) {
      return onClose();
    }

    setEditing(true);
  };

  useEffect(() => {
    async function loadProduct() {
      setLoading(true);

      try {
        const res = await api.get(`admin/products/get/${id}`);

        const { data } = res;

        if (data.product) {
          setProduct({
            ...data.product,
            price: data.price,
            product_price: util.currencyFormatter(data.product_price * 100),
            percent: data.percent,
            inventory_id: data.inventory_id,
            product_attributes: productService.parseAttributes(data.product.product_attributes),
            rule_expires_at: data.rule_expires_at
              ? util.getDateFromTimestamp(data.rule_expires_at)
              : '',
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      setEditing(true);

      loadProduct();
    }
  }, [id]);

  const handleChangeCover = (thumbnail, file) => {
    setProduct({ ...product, thumbnail });

    coverFileRef.current = file;
  };

  useEffect(() => {
    !editing && inputPriceRef?.current?.focus();
  }, [editing]);

  return (
    <form onSubmit={doSave} className="container-fluid p-3">
      {id && loading ? (
        <div className="p-5">
          <Spinner size="lg" className="d-block m-auto" color="primary" />
        </div>
      ) : (
        <>
          <fieldset>
            <Row form>
              <Col md="12">
                <Label>{util.t('PRODUCT_COVER')}</Label>
                <ImageInput
                  className="box-product-cover"
                  id="product-cover"
                  name="product-cover"
                  src={product?.thumbnailTenant ? product.thumbnailTenant : product?.thumbnail}
                  alt={util.t('PRODUCT_COVER')}
                  onChange={handleChangeCover}
                  disabled={editing}
                />
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label htmlFor="title">{util.t('TITLE')}</Label>
                  <Input className="form-control" value={product?.name} type="text" disabled />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label htmlFor="price">{util.t('ORIGINAL_VALUE')}</Label>
                  <NumberFormat
                    className="form-control"
                    defaultValue={util.format(product?.price)}
                    onChange={handleChange}
                    name="price"
                    id="price"
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    decimalScale={2}
                    prefix={'R$ '}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col lg="2">
                <FormGroup>
                  <Label htmlFor="discount_percent">{util.t('DISCOUNT_PERCENTAGE')}</Label>
                  <Input
                    className="form-control"
                    defaultValue={product?.percent}
                    value={product?.percent}
                    onChange={handleChangePriceDiscount}
                    type="number"
                    step="0.01"
                    min={0}
                    max={100}
                    name="discount_percent"
                    id="discount_percent"
                    disabled={editing}
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label htmlFor="product_price">{util.t('EDITED_VALUE')}</Label>
                  <NumberFormat
                    className="form-control"
                    value={product?.product_price}
                    onChange={handleChange}
                    name="product_price"
                    id="product_price"
                    format={util.currencyFormatter}
                    getInputRef={(ref) => (inputPriceRef.current = ref)}
                    disabled={editing}
                    autoFocus
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label htmlFor="rule_expires_at">{util.t('EXPIRATION_DATE')}</Label>
                  <Input
                    className="form-control"
                    onChange={handleChange}
                    type="date"
                    min={util.getCurrentDate()}
                    value={product?.rule_expires_at}
                    name="rule_expires_at"
                    id="rule_expires_at"
                    disabled={editing}
                    required={isModified}
                  />
                </FormGroup>
              </Col>
            </Row>
          </fieldset>
          <hr />
          <Row>
            <Col>
              <footer className="d-flex justify-content-end">
                <FormButtons
                  isEditing={editing}
                  isSaving={saving}
                  onCancel={cancelEdit}
                  onEdit={() => setEditing(false)}
                />
              </footer>
            </Col>
          </Row>
        </>
      )}
    </form>
  );
}

export default memo(Form);
