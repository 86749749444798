const TinyMCEConfig = {
  height: 500,
  menubar: false,
  advcode_inline: true,
  plugins:
    'searchreplace autolink directionality visualblocks visualchars image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap linkchecker emoticons autosave fullscreen code',
  toolbar:
    'undo redo | blocks | ' +
    'bold italic forecolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | ' +
    'removeformat | code | help',
  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
};

export default TinyMCEConfig;
