import React, { memo, useState } from 'react';

import { Button } from 'reactstrap';

import Modal from './Modal';

import util from '~/assets/util';

interface ImportSchoolButtonProps {
  onImport?: any;
  [key: string]: any;
}

function ImporSchoolButton(props: ImportSchoolButtonProps) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button {...props} color="primary" outline type="button" onClick={() => setShowModal(true)}>
        {util.t('IMPORT_SCHOOL')}
      </Button>

      <Modal
        isOpen={showModal}
        unmountOnClose
        toggle={() => setShowModal(false)}
        onImport={props.onImport}
      />
    </>
  );
}

export default memo(ImporSchoolButton);
