import React, { memo, useEffect, useState } from 'react';

import { Spinner } from 'reactstrap';

import Creatable from 'react-select/creatable';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

interface SerieSelectProps {
  schoolId?: number | string;
  onChange: any;
  [key: string]: any;
}

function SerieSelect({ onChange, schoolId = null, ...props }: SerieSelectProps) {
  const [loading, setLoading] = useState(false);
  const [series, setSeries] = useState([]);
  const [selectedSerie, setSelectedSerie] = useState(null);

  const loadSeries = async () => {
    setLoading(true);

    try {
      const res = await api.get(`admin/schools/${schoolId}/series`);

      const { series } = res?.data;

      setSeries(series);

      onChange(null);
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleCreate = (inputValue: string) => {
    const newSerie = { school_serie_id: null, name: inputValue };

    setSeries((prevSeries) => [...prevSeries, newSerie]);
    setSelectedSerie(newSerie);
  };

  useEffect(() => {
    onChange(selectedSerie);
  }, [selectedSerie]);

  useEffect(() => {
    if (!schoolId) {
      setSeries([]);

      return;
    }

    loadSeries();
  }, [schoolId]);

  if (loading) {
    return <Spinner color="primary" className="d-block mx-auto mb-3" size="sm" />;
  }

  return (
    <Creatable
      {...props}
      isClearable
      getOptionLabel={(opt) => opt.name || opt.label}
      getOptionValue={(opt) => opt.school_serie_id || opt.name}
      noOptionsMessage={() => util.t('NO_OPTION')}
      options={series}
      placeholder={util.t('SELECT_OR_TYPE_A_NEW_SERIE')}
      onChange={(value) => setSelectedSerie(value)}
      value={selectedSerie}
      formatCreateLabel={(name) => `${util.t('LABEL_INSERT')} "${name}"`}
      onCreateOption={handleCreate}
    />
  );
}

export default memo(SerieSelect);
