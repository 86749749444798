import React, { memo, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import {
  FormGroup,
  Label,
  Col,
  Row,
  Modal as BModal,
  Card,
  CardHeader,
  CardBody,
  Input,
} from 'reactstrap';

import Swal from 'sweetalert2';

import Button from '~/components/Button';

import api from '~/services/api';

import notification from '~/services/notification';

import util from '~/assets/util';

import './index.css';

interface ModalProps {
  toggle: any;
  onImport?: any;
  [key: string]: any;
}

function Modal({ toggle, onImport = null, ...props }: ModalProps) {
  const [loading, setLoading] = useState(false);

  const tenant = useSelector((state: any) => state.tenant);

  const fileRef = useRef(null);

  const doImport = async (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    if (loading) {
      return;
    }

    setLoading(true);

    const formData = new FormData();

    const file = fileRef.current.files[0];

    formData.append('document', file);
    formData.append('tenant_id', tenant.tenant_id);

    const url = '/admin/schools/register';

    try {
      const { data } = await api.post(url, formData);
      const imported = data?.school_list.imported;

      const result =
        data?.school_list.errors
          .map((error) => {
            const [message, line] = error.split(': ');
            return util.t(message).replace('{line}', line.trim());
          })
          .join(', ') || 0;

      const icon = data?.school_list.errors.length > 0 ? 'info' : 'success';
      Swal.fire({
        icon: icon,
        html: util
          .t('IMPORT_SCHOOL_SUCCESS')
          .replace('{total}', imported)
          .replace('{errors}', result),
      });

      if (onImport) {
        onImport && onImport();
      }

      toggle();
    } catch (e) {
      showErrors(e);

      fileRef.current.value = null;

      console.error('ImportSchoolButton.doSave', e);
    } finally {
      setLoading(false);
    }
  };

  const showErrors = (error) => {
    const data = error?.response?.data;

    if (!data?.message) {
      return notification.$e(error);
    }

    let message = util.t(data.message);

    const [text, value] = data.message.split(': ');
    if (text && value) {
      message = util.t(text).replace('{value}', value);
    }

    Swal.fire({
      icon: 'error',
      html: message,
    });
  };

  return (
    <BModal size="lg" toggle={toggle} {...props}>
      <Card>
        <CardHeader>{util.t('IMPORT_SCHOOL')}</CardHeader>
        <CardBody>
          <form onSubmit={doImport} className="container-fluid p-3">
            <Row form>
              <Col>
                <FormGroup>
                  <Label htmlFor="school-file">{util.t('SPREADSHEET')}</Label>
                  <Input
                    type="file"
                    className="form-control-file"
                    placeholder={util.t('SELECT')}
                    name="school-file"
                    id="school-file"
                    innerRef={fileRef}
                    required
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </FormGroup>
              </Col>
            </Row>

            <hr />

            <Row className="mt-3">
              <Col>
                <footer className="d-flex">
                  <Button loading={loading} type="submit" color="primary">
                    {util.t('IMPORT')}
                  </Button>
                </footer>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    </BModal>
  );
}

export default memo(Modal);
