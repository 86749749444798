import React, { memo, useCallback, useMemo } from 'react';

import { Badge, Button, Spinner, Table } from 'reactstrap';

import { FaSearchPlus } from 'react-icons/fa';

import { format, parseISO } from 'date-fns';

import CancelOrderButton from '../CancelOrderButton';
import ChangeOrderStatusButton from '../ChangeOrderStatusButton';
import MarkLocalPickupAvailableButton from '~/components/MarkLocalPickupAvailableButton';

import orderService from '~/services/order';

import util from '~/assets/util';

import { OrderStatus } from '~/assets/constants';
import { StoreSlug } from '~/store/constants';

interface OrdersTableProps {
  orders: any;
  loading?: any;
  onOpen?: any;
  setOrders?: any;
  onChangeOrderStatus?: any;
}

function OrdersTable({
  orders,
  loading,
  onOpen,
  setOrders = null,
  onChangeOrderStatus = null,
}: OrdersTableProps) {
  const updateCanceledOrders = useCallback(
    (storeOrders) => {
      const aux = [...orders.data];

      storeOrders.forEach((storeOrder) => {
        const id = storeOrder.store_order_id;
        const index = aux.findIndex((el) => el.store_order_id == id);
        if (index == -1) {
          return;
        }

        aux[index].status = storeOrder.status;
        aux[index].can_cancel = false;
      });

      setOrders({ ...orders, data: aux });
    },
    [orders, setOrders],
  );

  const list = useMemo(
    () =>
      orders?.data?.map((order) => {
        const status = orderService.getStatus(order.status);

        const id = order.store_order_id;

        return (
          <tr key={id}>
            <td>
              <b>{format(parseISO(order.created_at), 'dd/MM/yyyy HH:mm')}</b>
            </td>
            <td>{id}</td>
            <td>{order.order_id}</td>
            <td>{order.customer_name}</td>
            <td>{order.customer_email}</td>
            <td>R$ {util.format(order.subtotal)}</td>
            <td>
              <Badge color={status.color}>{status.label}</Badge>
            </td>
            <td>{order.store?.company_name}</td>
            <td className="d-flex align-center justify-content-end align-self-center">
              <Button
                className="mr-1"
                size="sm"
                outline
                color="info"
                type="button"
                onClick={() => onOpen(order.store_order_id)}>
                <FaSearchPlus />
              </Button>

              {order.store_slug == StoreSlug.CATAVENTO &&
                order.status != OrderStatus.STATUS_READY_FOR_PICKUP &&
                order.local_pick_up && (
                  <MarkLocalPickupAvailableButton
                    onSave={onChangeOrderStatus}
                    order={order}
                    storeOrderId={order.store_order_id}
                    className="mr-1"
                  />
                )}

              <ChangeOrderStatusButton
                onSave={onChangeOrderStatus}
                storeOrder={order}
                id={`btn-change-order-status-${id}`}
              />

              <CancelOrderButton order={order} onCancel={updateCanceledOrders} />
            </td>
          </tr>
        );
      }),
    [orders, onOpen, onChangeOrderStatus, updateCanceledOrders],
  );

  const renderBody = () => {
    if (loading) {
      return (
        <tr>
          <th className="text-center w-100" colSpan={9}>
            <Spinner size="sm" color="primary" />
          </th>
        </tr>
      );
    }

    if (!orders?.data?.length) {
      return (
        <tr>
          <td className="text-center" colSpan={9}>
            <span>{util.t('NO_RECORDS_FOUND')}</span>
          </td>
        </tr>
      );
    }

    return list;
  };

  return (
    <Table responsive hover>
      <thead className="thead-light">
        <tr>
          <th>{util.t('DATE')}</th>
          <th>{util.t('ORDER_NUMBER')}</th>
          <th>{util.t('MAIN_ORDER_NUMBER')}</th>
          <th>{util.t('CUSTOMER_NAME')}</th>
          <th>{util.t('CUSTOMER_EMAIL')}</th>
          <th>{util.t('VALUE')}</th>
          <th>{util.t('STATUS')}</th>
          <th>{util.t('DISTRIBUTION_CENTER_ABBRV')}</th>
          <th className="text-right">{util.t('ACTION')}</th>
        </tr>
      </thead>
      <tbody>{renderBody()}</tbody>
    </Table>
  );
}

export default memo(OrdersTable);
