import React, { memo } from 'react';

import { Card, CardBody, CardHeader, Modal } from 'reactstrap';

import Form from './Form';

import util from '~/assets/util';

interface DiscountModalProps {
  isOpen: boolean;
  onToggle: () => void;
  onSave: () => void;
}

function DiscountModal({ isOpen, onToggle, onSave }: DiscountModalProps) {
  return (
    <Modal autoFocus={false} isOpen={isOpen} toggle={onToggle} size="xl">
      <Card>
        <CardHeader>{util.t('CREATE_DISCOUNT')}</CardHeader>
        <CardBody>
          <Form onSave={onSave} />
        </CardBody>
      </Card>
    </Modal>
  );
}

export default memo(DiscountModal);
