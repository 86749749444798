import React, { memo, useRef, useState } from 'react';

import { Button, FormGroup, Label, Spinner } from 'reactstrap';

import SchoolSelect from '~/components/SchoolSelect';

import FinalStepForm from './FinalStepForm';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

import SerieSelect from '~/components/SerieSelect';
import ImportSchoolButton from '~/components/ImportSchoolButton';

interface FormProps {
  onClose: any;
  onSave: any;
}

function Form({ onClose, onSave }: FormProps) {
  const [highlightPendingImages, setHighlightPendingImages] = useState(false);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [saving, setSaving] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedSerie, setSelectedSerie] = useState(null);
  const [step, setStep] = useState(1);

  const appendImageButtonsRefs = useRef({});
  const formRef = useRef(null);

  const handleChangeSelect = (ev) => {
    ev && setSelectedProduct(ev);
  };

  const doSave = async (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    if (saving) {
      return false;
    }

    if (!products.length) {
      return notification.$e(util.t('YOU_SHOULD_ADD_AT_LEAST_ONE_PRODUCT'));
    }

    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      if (!product.pendingImage) {
        continue;
      }

      setHighlightPendingImages(true);

      setTimeout(() => {
        const buttonRef = appendImageButtonsRefs.current[product.id];

        buttonRef?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 1);

      notification.$e(util.t('ALL_PRODUCTS_SHOULD_HAVE_IMAGE'));

      return false;
    }

    let form = {};
    form = {
      products: products,
      serie: selectedSerie,
      school: selectedSchool,
    };

    setSaving(true);

    try {
      await api.post('admin/products/school-lists', form);

      onSave();
      onClose();

      notification.$s(util.t('SCHOOL_LIST_SAVED'));
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="p-5">
        <Spinner size="lg" className="d-block m-auto" color="primary" />
      </div>
    );
  }

  const renderStep = () => {
    if (step == 1) {
      return (
        <div className="school-list-step">
          <FormGroup>
            <Label for="school">{util.t('SELECT_OR_CREATE_A_SCHOOL')}</Label>
            <SchoolSelect
              id="school"
              name="school"
              onChange={(school) => setSelectedSchool(school)}
            />
          </FormGroup>
          <Button
            color="primary"
            type="button"
            onClick={() => setStep(2)}
            disabled={!selectedSchool}>
            {util.t('NEXT')}
          </Button>
          <br />
          <hr />
          {util.t('OR')}
          <br />
          <hr />
          <ImportSchoolButton
            onImport={() => {
              onSave();
              onClose();
            }}
          />
        </div>
      );
    }

    if (step == 2) {
      return (
        <div className="school-list-step">
          <FormGroup>
            <Label for="serie">{util.t('SELECT_OR_CREATE_A_SERIE')}</Label>
            <SerieSelect
              id="serie"
              name="serie"
              onChange={(serie) => setSelectedSerie(serie)}
              schoolId={selectedSchool?.school_id}
            />
          </FormGroup>

          <Button
            color="primary"
            type="button"
            onClick={() => setStep(3)}
            disabled={!selectedSerie}>
            {util.t('NEXT')}
          </Button>
        </div>
      );
    }

    return (
      <FinalStepForm
        setLoading={setLoading}
        selectedProduct={selectedProduct}
        products={products}
        setProducts={setProducts}
        highlightPendingImages={highlightPendingImages}
        handleChangeSelect={handleChangeSelect}
        setSelectedProduct={setSelectedProduct}
        appendImageButtonsRefs={appendImageButtonsRefs}
        onClose={onClose}
        saving={saving}
        formRef={formRef}
      />
    );
  };

  return (
    <form onSubmit={doSave} className="school-list-form container-fluid p-3" ref={formRef}>
      {renderStep()}
    </form>
  );
}

export default memo(Form);
