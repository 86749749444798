import React, { memo, useEffect, useState } from 'react';

import { FormGroup, Row, Col, Label, Input, Spinner, UncontrolledTooltip } from 'reactstrap';

import PaymentSettings from './PaymentSettings';

import FormButtons from '~/components/FormButtons';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

import { PaymentPlatforms } from '~/assets/constants';

function Form() {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);

  const [tenant, setTenant] = useState({
    tenant_id: '',
    mail_from_address: '',
    mail_password: '',
    mail_port: '',
    mail_login: '',
    mail_host: '',
    google_tagmanager: '',
    pagseguro_email: '',
    pagseguro_token: '',
    pagseguro_interest_rate: '',
    pagseguro_max_installments: '',
    pagseguro_installment_min_value: 0,
    pagseguro_interest_from_installment: '',
    pagseguro_pix_validity: 0,
    rede_email: '',
    rede_pv: '',
    rede_token: '',
    rede_max_installments: '',
    rede_installment_min_value: 0,
    card_platform: '',
    facebook_pixel: '',
    google_analytics: '',
    google_site_verification_code: '',
    winbooks_email: '',
    winbooks_password: '',
    franchising_id: 0,
  });

  const handleChange = (ev) => {
    const name = ev.target.name;

    setTenant({ ...tenant, [name]: ev.target.value });
  };

  const doSave = async (ev) => {
    ev.preventDefault();

    if (saving) {
      return false;
    }

    setSaving(true);

    try {
      const form = {
        ...tenant,
      };

      const url = `admin/tenants/update`;

      const res = await api.post(url, form);

      const data = res?.data;

      if (data.tenant_detail_id) {
        setEditing(true);

        notification.$s(util.t('GENERAL_INFORMATION_SAVED'));
      }
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  const cancelEdit = () => {
    setEditing(true);
  };

  useEffect(() => {
    async function loadSettings() {
      setLoading(true);

      try {
        const res = await api.get('admin/tenants');

        const tenant_data = res?.data;

        setTenant({ ...tenant_data });
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    setEditing(true);
    loadSettings();
  }, []);

  return (
    <>
      <form onSubmit={doSave} className="container-fluid p-3">
        {loading ? (
          <div className="p-5">
            <Spinner size="lg" className="d-block m-auto" color="primary" />
          </div>
        ) : (
          <>
            <Row>
              <Col lg="6">
                <fieldset disabled={editing}>
                  {!tenant.franchising_id && (
                    <>
                      <Row form>
                        <Col>
                          <legend>{util.t('PAYMENT')}</legend>
                          <hr />
                        </Col>
                      </Row>
                      <PaymentSettings
                        disabled={editing}
                        tenant={tenant}
                        onFieldChange={handleChange}
                      />
                      <Row className="mb-3" form>
                        <Col>
                          <Label htmlFor="card_platform">{util.t('CARD_PAYMENT_PLATFORM')}</Label>
                          <br />

                          <FormGroup check inline>
                            <Label check htmlFor={`platform-${PaymentPlatforms.PAGSEGURO}`}>
                              <Input
                                value={PaymentPlatforms.PAGSEGURO}
                                checked={tenant.card_platform == PaymentPlatforms.PAGSEGURO}
                                onChange={() =>
                                  setTenant({
                                    ...tenant,
                                    card_platform: PaymentPlatforms.PAGSEGURO,
                                  })
                                }
                                type="radio"
                                name="card_platform"
                                id={`platform-${PaymentPlatforms.PAGSEGURO}`}
                                required
                              />

                              {util.t('PAGSEGURO')}
                            </Label>
                          </FormGroup>

                          <FormGroup check inline>
                            <Label check htmlFor={`platform-${PaymentPlatforms.REDE}`}>
                              <Input
                                value={PaymentPlatforms.REDE}
                                checked={tenant.card_platform == PaymentPlatforms.REDE}
                                onChange={() =>
                                  setTenant({ ...tenant, card_platform: PaymentPlatforms.REDE })
                                }
                                type="radio"
                                name="card_platform"
                                id={`platform-${PaymentPlatforms.REDE}`}
                                required
                              />

                              {util.t('REDE')}
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row form>
                    <Col xs="12">
                      <legend>{util.t('ANALYTICS_WIDGETS')}</legend>
                      <hr />
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="facebook_pixel">{util.t('PIXEL_FACEBOOK')}</Label>
                        <Input
                          className="form-control"
                          value={tenant.facebook_pixel || ''}
                          onChange={(ev) =>
                            setTenant({
                              ...tenant,
                              facebook_pixel: ev.target.value.replace(' ', ''),
                            })
                          }
                          type="text"
                          name="facebook_pixel"
                          id="facebook_pixel"
                          placeholder="XXXXXXXXXXXXXX"
                        />
                      </FormGroup>
                    </Col>

                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="google_tagmanager">{util.t('GOOGLE_TAGMANAGER')}</Label>
                        <Input
                          className="form-control"
                          value={tenant.google_tagmanager || ''}
                          onChange={(ev) =>
                            setTenant({
                              ...tenant,
                              google_tagmanager: ev.target.value.replace(' ', ''),
                            })
                          }
                          type="text"
                          name="google_tagmanager"
                          id="google_tagmanager"
                          placeholder="GTM-XXXXXXX"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row form>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="google_analytics">{util.t('GOOGLE_ANALYTCS')}</Label>
                        <Input
                          className="form-control"
                          value={tenant.google_analytics || ''}
                          onChange={(ev) =>
                            setTenant({
                              ...tenant,
                              google_analytics: ev.target.value.replace(' ', ''),
                            })
                          }
                          type="text"
                          name="google_analytics"
                          id="google_analytics"
                          palceholder="UA-XXXXXXXX-X"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </fieldset>
              </Col>
              {!tenant.franchising_id && (
                <Col lg="6">
                  <fieldset disabled={editing}>
                    <Row form>
                      <Col>
                        <legend>{util.t('WINBOOKS_CREDENTIALS')}</legend>
                        <hr />
                      </Col>
                    </Row>
                    <Row form>
                      <Col xl={6}>
                        <Label htmlFor="winbooks_email">{util.t('EMAIL')}</Label>
                        <Input
                          id="winbooks_email"
                          name="winbooks_email"
                          type="email"
                          value={tenant.winbooks_email}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col xl={6}>
                        <Label htmlFor="winbooks_password">{util.t('PASSWORD')}</Label>
                        <Input
                          id="winbooks_password"
                          name="winbooks_password"
                          type="password"
                          value={tenant.winbooks_password}
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>

                    <Row form>
                      <Col>
                        <legend className="mt-4">{util.t('TRANSACTIONAL_MAILS')}</legend>
                        <hr />
                      </Col>
                    </Row>

                    <Row form>
                      <Col xl={6}>
                        <FormGroup>
                          <Label htmlFor="mail_from_address">{util.t('EMAIL')}</Label>
                          <Input
                            id="mail_from_address"
                            name="mail_from_address"
                            onChange={handleChange}
                            type="email"
                            value={tenant.mail_from_address}
                          />
                        </FormGroup>
                      </Col>
                      <Col xl={6}>
                        <FormGroup>
                          <Label htmlFor="mail_host">{util.t('MAIL_HOST')}</Label>
                          <Input
                            id="mail_host"
                            name="mail_host"
                            type="text"
                            onChange={handleChange}
                            value={tenant.mail_host || ''}
                          />

                          <UncontrolledTooltip target="mail_host" placement="right">
                            {util.t('LEAVE_HOST_FIELD_EMPTY')}
                          </UncontrolledTooltip>
                        </FormGroup>
                      </Col>
                    </Row>

                    <fieldset disabled={!tenant.mail_host}>
                      <Row form>
                        <Col xl={6}>
                          <FormGroup>
                            <Label htmlFor="mail_login">{util.t('LOGIN')}</Label>
                            <Input
                              id="mail_login"
                              name="mail_login"
                              type="text"
                              onChange={handleChange}
                              value={tenant.mail_login}
                              required={!!tenant.mail_host}
                            />
                          </FormGroup>
                        </Col>
                        <Col xl="6">
                          <FormGroup>
                            <Label htmlFor="mail_password">{util.t('PASSWORD')}</Label>
                            <Input
                              id="mail_password"
                              name="mail_password"
                              type="password"
                              autoComplete="off"
                              onChange={handleChange}
                              value={tenant.mail_password}
                              required={!!tenant.mail_host}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm="3">
                          <FormGroup>
                            <Label htmlFor="mail_port">{util.t('MAIL_PORT')}</Label>
                            <Input
                              id="mail_port"
                              name="mail_port"
                              type="number"
                              min={1}
                              onChange={handleChange}
                              value={tenant.mail_port}
                              required={!!tenant.mail_host}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </fieldset>

                    <Row form>
                      <Col xs="12">
                        <legend>{util.t('GOOGLE_PROPERTY_VERIFICATION')}</legend>
                        <hr />
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="google_site_verification_code">
                            {util.t('INSERT_HTML_OR_VERIFICATION_CODE')}
                          </Label>
                          <Input
                            className="form-control"
                            value={tenant.google_site_verification_code || ''}
                            onChange={handleChange}
                            type="text"
                            name="google_site_verification_code"
                            id="google_site_verification_code"
                            placeholder='<meta name="google-site-verification" content="Seu código" />"'
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </fieldset>
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <footer className="d-flex justify-content-end">
                  <FormButtons
                    isEditing={editing}
                    isSaving={saving}
                    onCancel={cancelEdit}
                    onEdit={() => {
                      setEditing(false);
                    }}
                  />
                </footer>
              </Col>
            </Row>
          </>
        )}
      </form>
    </>
  );
}

export default memo(Form);
