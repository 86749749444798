import React from 'react';

import { Link, useParams } from 'react-router-dom';

import { Button } from 'reactstrap';

import Form from './Form';

import util from '~/assets/util';

function Store() {
  const { id } = useParams();

  return (
    <>
      <main className="card my-3">
        <section className="card-body">
          <div className="container-fluid p-3">
            <header>
              <h1 className="h2 m-0">{util.t('DISTRIBUTION_CENTERS')}</h1>
            </header>

            <div className="text-right">
              <Link to="/stores">
                <Button className="my-3" color="primary" outline>
                  {util.t('BACK')}
                </Button>
              </Link>
            </div>

            <Form id={id} />

            <div className="d-flex justify-content-end mt-4"></div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Store;
