import React, { memo, useRef, useState, useEffect } from 'react';

import {
  Form as BForm,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner,
  CustomInput,
} from 'reactstrap';

import CurrencyInput from 'react-currency-masked-input';

import NumberFormat from 'react-number-format';

import { useNavigate } from 'react-router-dom';

import KeywordsInput from '../KeywordsInput';
import AttributesTable from '../AttributesTable';

import Button from '~/components/Button';
import ImageInput from '~/components/ImageInput';
import StoreSelect from '~/components/StoreSelect';
import StatusSwitch from '~/components/StatusSwitch';
import SearchBrandInput from '~/components/Brands/SearchBrandInput';
import SearchAuthorInput from '~/components/Authors/SearchAuthorInput';
import SearchCategoryInput from '~/components/Category/SearchCategoryInput';

import api from '~/services/api';
import notification from '~/services/notification';
import productService from '~/services/product';

import util from '~/assets/util';

import './index.scss';
import { ProductTypes } from '~/assets/constants';

function Form({ id = 0 }: any) {
  const DEFAULT_PRODUCT_STATE: any = {
    name: '',
    bar_code: '',
    description: '',
    thumbnail: '',
    weight: '',
    stock: '',
    price: '',
    active: true,
    keywords: '',
    brand_id: 0,
    brand: '',
    main_categories: [],
    product_attributes: [],
    type: '',
  };

  const [product, setProduct] = useState(DEFAULT_PRODUCT_STATE);

  const [storeId, setStoreId] = useState('');
  const [saving, setSaving] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [verifyingBarcode, setVerifyingBarcode] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const attributesRef = useRef([]);

  const barCodeRef = useRef(null);
  const coverFileRef = useRef(null);

  const navigate = useNavigate();

  const handleChange = (ev) => {
    const name = ev.target.name;
    setProduct({ ...product, [name]: ev.target.value });
  };

  const handleChangeWeight = (values) => {
    setProduct({ ...product, weight: values.floatValue });
  };

  const resetForm = (keepBarcode = false) => {
    setProduct({ ...DEFAULT_PRODUCT_STATE, bar_code: keepBarcode ? product.bar_code : '' });

    coverFileRef.current = null;

    setSelectedBrand(null);
    setSelectedAuthors([]);
    setSelectedCategories([]);
  };

  const doVerifyBarcode = async (ev) => {
    if (showForm) {
      return setShowForm(false);
    }

    if (verifyingBarcode) {
      return;
    }

    if (!barCodeRef.current.checkValidity()) {
      return barCodeRef.current.reportValidity();
    }

    setVerifyingBarcode(true);

    try {
      const barCode = util.escapeSpecialCharacters(product.bar_code);
      const { data } = await api.get(`admin/products/search/${barCode}/${storeId}`);

      const weight: any = data?.weight;
      const productData: any = data?.product;

      if (productData) {
        return loadProduct({ ...productData, weight });
      }

      resetForm(true);

      setShowForm(true);
    } catch (e) {
      console.error('doVerifyBarcode', e);

      notification.$e(e);
    } finally {
      setVerifyingBarcode(false);
    }
  };

  const getProductById = async (id) => {
    try {
      setLoadingProduct(true);

      const { data } = await api.get(`admin/products/get/${id}`);

      const productData: any = data?.product;

      loadProduct({ ...productData, weight: data.weight, stock: data.stock, price: data.price });
    } catch (e) {
      console.error('getProductById', e);

      navigate('/products');

      notification.$e(e);
    } finally {
      setLoadingProduct(false);
    }
  };

  const handleChangeCover = (thumbnail, file) => {
    setProduct({ ...product, thumbnail });

    coverFileRef.current = file;
  };

  const handleBarcodeKeyPress = (ev) => {
    if (ev.key != 'Enter') {
      return;
    }

    ev.preventDefault();

    doVerifyBarcode(ev);
  };

  const handleChangeBrand = (option) => {
    setSelectedBrand(option);

    const id = option?.value;
    const brand = option?.label;

    if (option?.__isNew__) {
      return setProduct({ ...product, brand, brand_id: 0 });
    }

    setProduct({ ...product, brand_id: id });
  };

  const handleCreateBrand = (brand) => {
    handleChangeBrand({
      value: brand.brand_id,
      label: brand.name,
    });
  };

  const handleChangeAuthor = (selected) => {
    setSelectedAuthors(selected);
  };

  const handleChangeCategory = (selected) => {
    setSelectedCategories((prev) => {
      if (Array.isArray(selected)) {
        return selected;
      }

      return [...prev, selected];
    });
  };

  const doSave = async (ev) => {
    ev.preventDefault();

    if (saving) {
      return;
    }

    if (!product.brand_id && !product.brand) {
      return notification.$e(util.t('SELECT_A_BRAND'));
    }

    if (!selectedCategories.length) {
      return notification.$e(util.t('SELECT_A_CATEGORY'));
    }

    setSaving(true);

    const form = new FormData();

    form.append('name', product.name);
    form.append('stock', parseInt(product.stock) + '');
    form.append('price', product.price);
    form.append('brand', product.brand);
    form.append('weight', product.weight);
    form.append('bar_code', product.bar_code);
    form.append('brand_id', product.brand_id);
    form.append('thumbnail', product.thumbnail);
    form.append('description', product.description);
    form.append('type', product.type);
    form.append('active', product.active ? '1' : '0');

    product.keywords && form.append('keywords', product.keywords);
    product.product_id && form.append('product_id', product.product_id);

    form.append('store_id', storeId);

    form.append('attributes', JSON.stringify(attributesRef.current));

    selectedAuthors.forEach((option) => form.append('authors[]', option.value));
    selectedCategories.forEach((option) => form.append('categories[]', option.category_id));

    const coverFile = coverFileRef?.current;
    if (coverFile) {
      form.append('cover', coverFile);
    }

    try {
      const url = id ? `admin/products/${id}` : 'admin/products';

      await api.post(url, form, { headers: { 'Content-Type': 'multipart/form-data' } });

      notification.$s(null, util.t('PRODUCT_SAVED'), true);

      resetForm();
      setShowForm(false);

      if (id) {
        navigate('/products');
      }
    } catch (e) {
      console.error('productForm.doSave', e);

      notification.$e(e);
    } finally {
      setSaving(false);
    }
  };

  const handleCreateAuthor = (author) => {
    const newOption = {
      label: author.name,
      value: author.author_id,
    };

    setSelectedAuthors(selectedAuthors.concat(newOption));
  };

  const loadProduct = (productData) => {
    productData.product_attributes = productService.parseAttributes(productData.product_attributes);

    setProduct({ ...product, ...productData });

    const authors = productData?.authors;
    const selectedAuthors = authors?.map((author) => ({
      label: author.name,
      value: author.author_id,
    }));

    setSelectedAuthors(selectedAuthors);

    const mainCategories = productData?.main_categories;

    setSelectedCategories(mainCategories);

    const brand = productData?.brand;
    if (brand) {
      setSelectedBrand({ value: brand.brand_id, label: brand.name });
    }

    setShowForm(true);
  };

  useEffect(() => {
    id && getProductById(id);
  }, []);

  useEffect(() => {
    barCodeRef.current?.focus();
  }, [storeId]);

  if (loadingProduct) {
    return <Spinner color="primary" className="d-block m-auto" />;
  }

  return (
    <BForm onSubmit={doSave}>
      {!id && (
        <Row form>
          <Col lg="4">
            <Label htmlFor="store_id"> {util.t('DISTRIBUTION_CENTER')} </Label>

            <StoreSelect
              className="mb-3"
              id="store_id"
              name="store_id"
              onChange={(id) => setStoreId(id)}
              disabled={showForm}
            />
          </Col>
        </Row>
      )}
      <Row form>
        <Col lg="4">
          <FormGroup>
            <Label htmlFor="barcode">{util.t('BARCODE')}</Label>
            <Input
              innerRef={barCodeRef}
              disabled={(!storeId || showForm) && !id}
              className="form-control"
              value={product.bar_code}
              onChange={handleChange}
              type="text"
              maxLength={255}
              name="bar_code"
              id="bar_code"
              onKeyPress={handleBarcodeKeyPress}
              required
              autoFocus
            />
          </FormGroup>
        </Col>
      </Row>

      {!id && (
        <Row form>
          <Col>
            <Button
              disabled={!storeId}
              color={showForm ? 'danger' : 'primary'}
              type="button"
              onClick={doVerifyBarcode}
              loading={verifyingBarcode}>
              {util.t(showForm ? 'CANCEL' : 'CONTINUE')}
            </Button>
          </Col>
        </Row>
      )}
      {showForm && (
        <fieldset className="mt-5">
          <Row form>
            <Col lg="6">
              <Row form>
                <Col>
                  <FormGroup>
                    <Label htmlFor="name">{util.t('NAME')} *</Label>
                    <Input
                      className="form-control"
                      value={product.name}
                      onChange={handleChange}
                      type="text"
                      maxLength={255}
                      name="name"
                      id="name"
                      required
                      autoFocus={!product.name}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col lg="6">
                  <FormGroup>
                    <Label>{util.t('PRODUCT_TYPE')}*</Label>
                    <div className="d-flex">
                      <CustomInput
                        type="radio"
                        id="product_type_book"
                        name="type"
                        label={util.t('BOOK')}
                        value={ProductTypes.BOOK}
                        checked={product.type === ProductTypes.BOOK}
                        onChange={handleChange}
                        className="mr-3"
                        required
                      />
                      <CustomInput
                        type="radio"
                        id="product_type_other"
                        name="type"
                        label={util.t('OTHER')}
                        value={ProductTypes.OTHER}
                        checked={product.type === ProductTypes.OTHER}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col xl="4">
                  <FormGroup>
                    <Label htmlFor="weight">{util.t('WEIGHT')} *</Label>

                    <NumberFormat
                      className="form-control"
                      value={product.weight}
                      onValueChange={handleChangeWeight}
                      maxLength={255}
                      name="weight"
                      id="weight"
                      suffix=" kg"
                      decimalSeparator=","
                      allowNegative={false}
                      placeholder={util.t('WEIGHT_EXAMPLE_PLACEHOLDER')}
                      required
                    />
                  </FormGroup>
                </Col>

                <Col xl="4">
                  <FormGroup>
                    <Label htmlFor="price">{util.t('PRICE')} *</Label>

                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>R$</InputGroupText>
                      </InputGroupAddon>
                      <CurrencyInput
                        autoComplete="off"
                        className="form-control"
                        value={product.price + ''}
                        onChange={(ev, price) => setProduct({ ...product, price })}
                        name="price"
                        id="price"
                        min={0}
                        required
                        placeholder="-"
                        autoFocus={!!product.name}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>

                <Col xl="4">
                  <FormGroup>
                    <Label htmlFor="stock">{util.t('QUANTITY_STOCK')} *</Label>

                    <Input
                      id="stock"
                      name="stock"
                      type="number"
                      step={1}
                      min={0}
                      value={product.stock}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label htmlFor="keywords">{util.t('KEYWORDS')}</Label>
                    <KeywordsInput
                      id="keywords"
                      name="keywords"
                      keywordsString={product.keywords || ''}
                      onChangeString={(keywords) => setProduct({ ...product, keywords })}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col lg="6">
              <Label>{util.t('PRODUCT_COVER')}</Label>
              <ImageInput
                className="box-product-cover"
                id="product-cover"
                name="product-cover"
                src={product.thumbnail}
                alt={util.t('PRODUCT_COVER')}
                onChange={handleChangeCover}
              />
            </Col>
          </Row>
          <Row form>
            <Col>
              <FormGroup>
                <Label htmlFor="description">{util.t('DESCRIPTION')} *</Label>
                <textarea
                  className="form-control"
                  value={product.description}
                  onChange={handleChange}
                  name="description"
                  id="description"
                  required
                />
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col lg="4">
              <FormGroup>
                <Label htmlFor="brand_select">{util.t('BRAND')} *</Label>

                <SearchBrandInput
                  id="brand_select"
                  value={selectedBrand}
                  onChange={handleChangeBrand}
                  onCreate={handleCreateBrand}
                  canCreate={false}
                />
              </FormGroup>
            </Col>

            <Col lg="4">
              <FormGroup>
                <Label htmlFor="categories_select">{util.t('CATEGORY_OR_CATEGORIES')} *</Label>
                <SearchCategoryInput
                  id="categories_select"
                  value={selectedCategories}
                  isMulti
                  onChange={handleChangeCategory}
                />
              </FormGroup>
            </Col>

            <Col lg="4">
              <FormGroup>
                <Label htmlFor="authors_select">{util.t('AUTHOR_OR_AUTHORS')}</Label>

                <SearchAuthorInput
                  id="authors_select"
                  value={selectedAuthors}
                  isMulti
                  onChange={handleChangeAuthor}
                  onCreate={handleCreateAuthor}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col xl="10">
              <FormGroup>
                <Label>{util.t('ATTRIBUTES')}</Label>
                <AttributesTable
                  onChange={(attributes) => (attributesRef.current = attributes)}
                  attributes={product.product_attributes}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col>
              <FormGroup>
                <Label htmlFor="active">{util.t('STATUS')} *</Label>
                <StatusSwitch
                  id="active"
                  onChange={(active) => setProduct({ ...product, active })}
                  checked={!!product.active}
                  name="active"
                />
              </FormGroup>
            </Col>
          </Row>

          <Button className="mt-5" color="primary" type="submit" loading={saving}>
            {util.t('SAVE')}
          </Button>
        </fieldset>
      )}
    </BForm>
  );
}

export default memo(Form);
